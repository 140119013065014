import { Component, OnInit, ViewChild } from '@angular/core';
import { TaskModel, TaskFormBuilder } from 'src/app/shared/models/task-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TaskService } from 'src/app/core/services/task.service';
import { MessageService } from 'src/app/shared/factory/message.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {

  displayedColumns = ['id', 'name', 'dueDate', 'project.name', 'status', 'action'];
  dataSource: MatTableDataSource<TaskModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  task: TaskFormBuilder = new TaskFormBuilder();
  taskModel: TaskModel = this.task.taskModel;

  constructor(
    private taskService: TaskService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.fetchTasks();
    this.dataSource = new MatTableDataSource<TaskModel>([]);
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  fetchTasks(){
      this.taskService.fetchTasks().subscribe((res: TaskModel[]) => {
        this.dataSource = new MatTableDataSource<TaskModel>(res);
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  /**
   * Update task status 
   * @param id 
   */
  updateTaskStatus(id:any){
    this.taskModel.id = id;
    this.taskModel.status = "CLOSED";
    this.taskService.updateTaskStatus(this.taskModel).subscribe((res) => {
    this.messageService.display('Task status updated successfully! Task Id : ' + res, 'OK', 'success');
    this.fetchTasks();
    });
  }

  /**
   * For support of sorting of nested objects
   * @param item 
   * @param property 
   */
  sortingDataAccessor(item, property) {
    if (property.includes('.')) {
      return property.split('.')
        .reduce((object, key) => object[key], item);
    }
    return item[property];
  }
  
}
