import { FormGroup, FormControl, Validator, Validators, FormArray } from '@angular/forms';

export interface CompensatoryRequestActionModel {
    encashmentCompensatoryRequestId : number,
    compensatoryApprover : Array<any>,
    encashmentCompensatoryRequestApprovers : any,
    managerCompensatoryRequestStatus : string,
    hrCompensatoryRequestStatus : string,
    projectName: string,
    tasks: string,
    date: string,
    entryTime: string,
    exitTime: string,
    approverActionComment: string,
    hrActionComment: string,
    userId : number,
    listOfUserForNotificationReference : Array<any>,
    compensation : string,
    encashmentCompensatoryType : string,
    salary : number
}

export class CompensatoryRequestActionBuilder{
    compensatoryRequestActionModel : CompensatoryRequestActionModel = {
        encashmentCompensatoryRequestId : null,
        compensatoryApprover : [],
        encashmentCompensatoryRequestApprovers : [],
        managerCompensatoryRequestStatus : '',
        hrCompensatoryRequestStatus: '',
        projectName: '',
        tasks: '',
        date: null,
        entryTime: '',
        exitTime: '',
        approverActionComment: '',
        hrActionComment: '',
        userId : null,
        listOfUserForNotificationReference : [],
        encashmentCompensatoryType : '',
        compensation : '',
        salary : null
    };

    compensatoryRequestActionForm : FormGroup =new FormGroup({
        encashmentCompensatoryRequestId : new FormControl(this.compensatoryRequestActionModel.encashmentCompensatoryRequestId),
        compensatoryApprover : new FormControl(this.compensatoryRequestActionModel.compensatoryApprover),
        encashmentCompensatoryRequestApprovers : new FormControl(this.compensatoryRequestActionModel.encashmentCompensatoryRequestApprovers),
        managerCompensatoryRequestStatus : new FormControl(this.compensatoryRequestActionModel.managerCompensatoryRequestStatus),
        hrCompensatoryRequestStatus : new FormControl(this.compensatoryRequestActionModel.hrCompensatoryRequestStatus),
        projectName: new FormControl(this.compensatoryRequestActionModel.projectName),
        tasks: new FormControl(this.compensatoryRequestActionModel.tasks),
        date: new FormControl(this.compensatoryRequestActionModel.date),
        entryTime: new FormControl(this.compensatoryRequestActionModel.entryTime),
        exitTime: new FormControl(this.compensatoryRequestActionModel.exitTime),
        approverActionComment: new FormControl(this.compensatoryRequestActionModel.approverActionComment),
        hrActionComment: new FormControl(this.compensatoryRequestActionModel.hrActionComment),
        userId : new FormControl(this.compensatoryRequestActionModel.userId,Validators.required),
        listOfUserForNotificationReference : new FormControl(this.compensatoryRequestActionModel.listOfUserForNotificationReference),
        encashmentCompensatoryType : new FormControl(this.compensatoryRequestActionModel.encashmentCompensatoryType),
        compensation : new FormControl(this.compensatoryRequestActionModel.compensation),
        salary : new FormControl(this.compensatoryRequestActionModel.salary),


    });
    
    compensatoryRequestModel : CompensatoryRequestActionBuilder;

    constructor() {
        this.compensatoryRequestActionForm.valueChanges.subscribe(val => {
            this.compensatoryRequestActionModel.encashmentCompensatoryRequestId = val.encashmentCompensatoryRequestId;
            this.compensatoryRequestActionModel.compensatoryApprover = val.compensatoryApprover;
            this.compensatoryRequestActionModel.encashmentCompensatoryRequestApprovers = val.encashmentCompensatoryRequestApprovers;
            this.compensatoryRequestActionModel.managerCompensatoryRequestStatus = val.managerCompensatoryRequestStatus;
            this.compensatoryRequestActionModel.hrCompensatoryRequestStatus = val.hrCompensatoryRequestStatus;
            this.compensatoryRequestActionModel.projectName = val.projectName;
            this.compensatoryRequestActionModel.tasks = val.tasks;
            this.compensatoryRequestActionModel.date = val.date;
            this.compensatoryRequestActionModel.entryTime = val.entryTime;
            this.compensatoryRequestActionModel.exitTime = val.exitTime;
            this.compensatoryRequestActionModel.approverActionComment = val.approverActionComment;
            this.compensatoryRequestActionModel.hrActionComment = val.hrActionComment;
            this.compensatoryRequestActionModel.userId = val.userId;
            this.compensatoryRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            this.compensatoryRequestActionModel.encashmentCompensatoryType = val.encashmentCompensatoryType;
            this.compensatoryRequestActionModel.compensation = val.compensation;
            this.compensatoryRequestActionModel.salary = val.salary;


        });
    }
   setModelVals(res:CompensatoryRequestActionModel){
    this.compensatoryRequestActionForm.controls.encashmentCompensatoryRequestId.setValue(res.encashmentCompensatoryRequestId);
    this.compensatoryRequestActionForm.controls.compensatoryApprover.setValue(res.compensatoryApprover);
    this.compensatoryRequestActionForm.controls.encashmentCompensatoryRequestApprovers.setValue(res.encashmentCompensatoryRequestApprovers);
    this.compensatoryRequestActionForm.controls.managerCompensatoryRequestStatus.setValue(res.managerCompensatoryRequestStatus);
    this.compensatoryRequestActionForm.controls.hrCompensatoryRequestStatus.setValue(res.hrCompensatoryRequestStatus);
    this.compensatoryRequestActionForm.controls.projectName.setValue(res.projectName);
    this.compensatoryRequestActionForm.controls.tasks.setValue(res.tasks);
    this.compensatoryRequestActionForm.controls.date.setValue(res.date);
    this.compensatoryRequestActionForm.controls.entryTime.setValue(res.entryTime);
    this.compensatoryRequestActionForm.controls.exitTime.setValue(res.exitTime);
    this.compensatoryRequestActionForm.controls.approverActionComment.setValue(res.approverActionComment);
    this.compensatoryRequestActionForm.controls.hrActionComment.setValue(res.hrActionComment);
    this.compensatoryRequestActionForm.controls.userId.setValue(res.userId);
    this.compensatoryRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
    this.compensatoryRequestActionForm.controls.encashmentCompensatoryType.setValue(res.encashmentCompensatoryType);
    this.compensatoryRequestActionForm.controls.compensation.setValue(res.compensation);
    this.compensatoryRequestActionForm.controls.salary.setValue(res.salary);


   }
}
