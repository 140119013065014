

import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface ProjectHourlyStatsModel {
    projectName : string,
    taskName : string,
    seconds : number,
    date : any
}

export class ProjectHourlyStatsBuilder {
        projectHourlyStatsModel: ProjectHourlyStatsModel = {
        projectName : '',
        taskName : '',
        seconds : null,
        date : ''
    };

    projectHourlyStatsForm: FormGroup = new FormGroup({
       projectName : new FormControl(this.projectHourlyStatsModel.projectName),
       taskName : new FormControl(this.projectHourlyStatsModel.taskName),
       seconds : new FormControl(this.projectHourlyStatsModel.seconds),
       date : new FormControl(this.projectHourlyStatsModel.date)
       
    });
    constructor() {
        this.projectHourlyStatsForm.valueChanges.subscribe(val => {
            this.projectHourlyStatsModel.projectName = val.projectName;
            this.projectHourlyStatsModel.taskName = val.taskName;
            this.projectHourlyStatsModel.seconds = val.seconds;
            this.projectHourlyStatsModel.date = val.date;
           
            
        });
    }
    setModelVals(res: ProjectHourlyStatsModel) {
      this.projectHourlyStatsForm.controls.projectName.setValue(res.projectName);
      this.projectHourlyStatsForm.controls.taskName.setValue(res.taskName);
      this.projectHourlyStatsForm.controls.seconds.setValue(res.seconds);
      this.projectHourlyStatsForm.controls.date.setValue(res.date);
    }
}