import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ActivitySampleScreenshotComponent } from './components/activity-sample-screenshot/activity-sample-screenshot.component';
import { HomeComponent } from './components/home/home.component';
import { DailyTimesheetComponent } from './components/daily-timesheet/daily-timesheet.component';
import { WeeklyTimesheetComponent } from './components/weekly-timesheet/weekly-timesheet.component';
import { LoginComponent } from './components/login/login.component';
import { SSOGatewayComponent } from './components/sso-gateway/sso-gateway.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TaskComponent } from './components/task/task.component';
import { TaskAddEditComponent } from './components/task-add-edit/task-add-edit.component';
import { ProjectIntegrationComponent } from './components/project-integration/project-integration.component';
import { MonthlyTimesheetComponent } from './components/monthly-timesheet/monthly-timesheet.component';
import { AttendanceLogFileComponent } from './components/attendance-log-file/attendance-log-file.component';
import { MyCompensatoryComponent } from './components/my-extra-work-hours/my-extra-work-hours.component';
import { ApplyCompensatoryComponent } from './components/apply-ewh/apply-ewh.component';
import { CompensatoryActionComponent } from './components/ewh-action/ewh-action.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sso-gateway', component: SSOGatewayComponent },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'activitySampleScreenshot', component: ActivitySampleScreenshotComponent },
      { path: 'dailyTimesheet', component: DailyTimesheetComponent },
      { path: 'weeklyTimesheet', component: WeeklyTimesheetComponent },
      { path: 'monthlyTimesheet', component: MonthlyTimesheetComponent },
      { path: 'tasks', component: TaskComponent },
      { path: 'tasks/:taskId', component: TaskAddEditComponent },
      { path: 'tasks/new', component: TaskAddEditComponent },
      { path: 'project-integration', component: ProjectIntegrationComponent },
      { path: 'activity-log', component: AttendanceLogFileComponent },
      { path: 'myEncashmentCompensatory', component: MyCompensatoryComponent },
      { path: 'applyCompensatory/:new', component: ApplyCompensatoryComponent },
      { path: 'applyCompensatory/:id', component: ApplyCompensatoryComponent },
      { path: 'compensatoryAction', component: CompensatoryActionComponent },
    ]
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] } // need to work on design
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
    // { enableTracing: true } // <-- debugging purposes only
  )],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
