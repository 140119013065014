import { Injectable } from '@angular/core';
import { CompensatoryRequestActionModel } from 'src/app/shared/models/ewh-request-action-model';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompensatoryRequestApproverService {
  private stateUrl = 'api/v1/action/compensatory-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchAllPendingCompensatoryRequest(): Observable<CompensatoryRequestActionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  approveCompensatory(compensatoryRequestActionModel: CompensatoryRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved', compensatoryRequestActionModel);
  }

  rejectCompensatory(compensatoryRequestActionModel: CompensatoryRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejected', compensatoryRequestActionModel);
  }

  fetchAllApprovedCompensatoryRequest(): Observable<CompensatoryRequestActionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/compensation');
  }

  adjustCompensatory(compensatoryRequestActionModel: any): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/adjusted', compensatoryRequestActionModel);
  }

  declineCompensatory(compensatoryRequestActionModel: CompensatoryRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/declined', compensatoryRequestActionModel);
  }
}
