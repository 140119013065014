import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { EmployeeService } from '../../core/services/employee.service';
import { EmployeeModel, EmployeeModelBuilder } from 'src/app/shared/models/employee-model';
import { DashboardStatisticService } from '../../core/services/dashboard-statistic.service';
import { ProjectWorkHoursModel, ProjectWorkHoursBuilder } from '../../shared/models/project-work-hours-model';
import { Subject } from 'rxjs';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';


import { AppDateAdapter } from '../helper/format-datepicker';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';



export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'current_month',
    monthYearLabel: 'MMMM d, y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};


@Component({
  selector: 'app-monthly-timesheet',
  templateUrl: './monthly-timesheet.component.html',
  styleUrls: ['./monthly-timesheet.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class MonthlyTimesheetComponent implements OnInit {

  dateHeaders: Map<string, any> = new Map();
 
  screenshot = {
    img: './assets/images/shutterstock.jpg'
  };

  date1 = new FormControl(new Date());

  employees: EmployeeModel[] = [];
  selectedEmpBuilder: EmployeeModelBuilder = new EmployeeModelBuilder();
  selectedEmpModel: EmployeeModel = this.selectedEmpBuilder.employeeModel;
  selectedEmp = new FormControl(new EmployeeModelBuilder().employeeModel.id);
  prevBtnImg = {
    img: './assets/images/icons8-prev-50.png'
  }
  nextBtnImg = {
    img: './assets/images/icons8-right-button-50.png'
  }
  monthlyprojectTaskDetails = [];
  time = 0;
  view: string = 'month';
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  isLoaderMonthly: boolean = false;


  constructor(
    public location: Location,
    private employeeService: EmployeeService,
    private dashboardStatisticService: DashboardStatisticService,
    private eleRef: ElementRef,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.fetchMonthlyTimesheet();
    this.fetchEmployees();
  }

  fetchEmployees() {
    if (sessionStorage.getItem('employees')) {
      this.employees = JSON.parse(sessionStorage.getItem('employees'));
    } else {
      this.employeeService.fetchLiteEmployees().subscribe(res => {
        this.employees = res;
      });
    }
  }

  onSelect(event) {
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  addEvent(date, project, task, client, time): void {
    this.events.push({
      id: date,
      title: 'Project :' + project + ' ' + '    <br> Task :' + task,
      start: addHours(new Date(date).setSeconds(time), 0),
      end: addHours(new Date(date).setSeconds(time), 1),
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false
      }
    });
    this.refresh.next();
  }

  handleHourSegament(event: CalendarEvent) {
  }

   previousDay() {
     var currDate = new Date(this.date1.value);    
     this.date1 = new FormControl(new Date(currDate.setMonth(currDate.getMonth() - 1)));
     this.fetchMonthlyTimesheet();
   }

   nextDay() {
     var currDate = this.date1.value;
     this.date1 = new FormControl(new Date(currDate.setMonth(currDate.getMonth() + 1)));
     this.fetchMonthlyTimesheet();
   
   }

  fetchMonthlyTimesheet() {
    this.isLoaderMonthly = true;
    var date = dateTimeFormatters.formatDate(this.date1.value);
    this.dateHeaders.clear();
    this.events = [];
    if (this.selectedEmp.value != -1) {
      this.dashboardStatisticService.fetchMonthlyTimesheetForEmp(date, this.selectedEmp.value).subscribe((res: ProjectWorkHoursModel[]) => {
        this.populateTable(res);
      });
    } else {
      this.dashboardStatisticService.fetchMonthlyTimesheet(date).subscribe((res: ProjectWorkHoursModel[]) => {
        this.populateTable(res);
      });
    }
  }

  populateDateRange(d) {
    var currentWeekDay = d.getDay();
    var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
    var wkStart = new Date(new Date(d).setDate(d.getDate() - lessDays));
    this.dateHeaders.clear();
    for (let i = 1; i <= 7; i++) {
      var dateStr = dateTimeFormatters.formatDate(wkStart);
      this.dateHeaders.set(dateStr, 0);
      wkStart = new Date(wkStart.setDate(wkStart.getDate() + 1));
    }
  }

  timeConvert(val) {
    let h = Math.floor(val / 3600);
    val %= 3600;
    let m = Math.floor(val / 60);
    let s = (val % 60);

    var min = String(m).padStart(2, "0");
    var hr = String(h).padStart(2, "0");
    var sec = String(s).padStart(2, "0");

    var str = hr + ":" + min + ":" + sec;
    if (str == '00:00:00') {
      str = '-';
    }
    return str;
  }

  populateTable(res) {
    if (res.length) {
      this.populateDateRange(this.date1.value);
    }
    this.isLoaderMonthly = false;
    this.monthlyprojectTaskDetails = [];
    this.events = [];
    //this.time = 0;
    this.monthlyprojectTaskDetails = res;
    for (let i of this.monthlyprojectTaskDetails) {
     // this.time = i.seconds;
      for (let projectTasks of i.projectTaskDetails) {
        this.addEvent(i.date, projectTasks.key, projectTasks.value['task'], projectTasks.value['client'],  i.seconds);
      }
    }
    
  }
}

