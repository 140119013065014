import { Validators, FormGroup, FormControl } from '@angular/forms';
import { dateTimeFormatters } from '../factory/common.service';

export interface TaskModel {
    id: number;
    name: string;
    description: string;
    dueDate: any;
    project: 
        {
            id: number;
            name:string;
        };
    status: any    
}

export class TaskFormBuilder {

    taskModel: TaskModel = {
        id: null, 
        name: '',
        description: '',
        dueDate: null,
        project:null,
        status:null
    };

    taskForm: FormGroup = new FormGroup({
        name: new FormControl(this.taskModel.name, [Validators.required]),
        description: new FormControl(this.taskModel.description,[Validators.required]),
        dueDate: new FormControl(this.taskModel.dueDate,[Validators.required]),
        project: new FormControl(this.taskModel.project,[Validators.required]),
        status: new FormControl(this.taskModel.status)
    });

    constructor() {
            this.taskForm.valueChanges.subscribe(val => {
                this.taskModel.name = val.name;
                this.taskModel.description = val.description;
                this.taskModel.dueDate = dateTimeFormatters.formatDate(val.dueDate);
                this.taskModel.project = val.project;
                this.taskModel.status = val.status;
        })
    }

    setModelVals(res: TaskModel) {
        this.taskForm.controls.name.setValue(res.name);
        this.taskForm.controls.description.setValue(res.description);
        this.taskForm.controls.dueDate.setValue(res.dueDate);
        this.taskForm.controls.project.setValue(res.project);
        this.taskForm.controls.status.setValue(res.status);
    }
}