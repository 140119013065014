import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { JiraIntegrationModel } from 'src/app/shared/models/Jira-integration-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserjiraintegrationdetailService {

  private stateUrl = 'api/v1/jira-integration-detail';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addJiraIntegrationDetail(jiraIntegrationModel: JiraIntegrationModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/add-project-integration', jiraIntegrationModel);
  }

  updateJiraIntegrationDetail(jiraIntegrationModel: JiraIntegrationModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-project-integration', jiraIntegrationModel);
  }

  checkIntegrationExist(projectId:number): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/check-integration-exist/'+projectId);
  }

  /**
   * common service for get list of integration list corresponding to project
   * @param projectId 
   */
  fetchIntegrationDetails(projectId:number): Observable<JiraIntegrationModel> {
    return this.httpRequest.get(this.stateUrl + '/fetch-integration-details/'+projectId);
  }

}
