import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompensatoryApplyRequestModel } from 'src/app/shared/models/ewh-apply-request-model';
import { HttpRequestService } from '../http/http-request.service';
import { CompensatoryRequestModel } from 'src/app/shared/models/ewh-request-model';

@Injectable({
  providedIn: 'root'
})
export class CompensatoryRequestService {

  //for api call
  private stateUrl = 'api/v1/compensatory-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addCompensatoryRequest(compensatoryApplyModel: CompensatoryApplyRequestModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + "/new", compensatoryApplyModel);
  }

  updateCompensatoryRequest(compensatoryApplyModel: CompensatoryApplyRequestModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + "/update", compensatoryApplyModel);
  }

  fetchCompensatoryByIdWithDetail(Id): Observable<CompensatoryRequestModel> {
    return this.httpRequest.get(this.stateUrl + '/' + Id);
  }
}
