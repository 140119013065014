import { Component, OnInit } from '@angular/core';
import { TimesheetTaskDetailsBuilder, TimesheetTaskDetailsModel } from '../../shared/models/timesheet-task-details-model';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { DashboardStatisticService } from '../../core/services/dashboard-statistic.service';
import { Location, KeyValue } from '@angular/common';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { EmployeeService } from '../../core/services/employee.service';
import { EmployeeModel, EmployeeModelBuilder } from 'src/app/shared/models/employee-model';
import { MatDateFormats, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { AppDateAdapter } from '../helper/format-datepicker';

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'range',
    monthYearLabel: 'MMMM d, y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-daily-timesheet',
  templateUrl: './daily-timesheet.component.html',
  styleUrls: ['./daily-timesheet.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class DailyTimesheetComponent implements OnInit {

  timesheetTaskDetails: TimesheetTaskDetailsBuilder = new TimesheetTaskDetailsBuilder();
  timesheetTaskDetailsModel: TimesheetTaskDetailsModel = this.timesheetTaskDetails.timesheetTaskDetailsModel;
  timesheetTaskDetailsModelForm: FormGroup = this.timesheetTaskDetails.timesheetTaskDetailsModelForm;
  startDate = '';
  endDate = '';
  timesheetTaskDetail = [];
  projectTaskMap = new Map<string, any>();
  timesheetDatesValue = [];
  date = new FormControl(new Date());
  employees: EmployeeModel[] = [];
  selectedEmp = new FormControl(new EmployeeModelBuilder().employeeModel.id)
  detailsMap = new Map<any, any>();
  dailyTotalHours = new Map<any, any>();
  showNoContentLabel = false;
  shutterstock = {
    img: './assets/images/shutterstock.jpg'
  };
  prevBtnImg = {
    img: './assets/images/icons8-prev-50.png'
  }
  nextBtnImg = {
    img: './assets/images/icons8-right-button-50.png'
  }


  constructor(
    private dashboardStatisticService: DashboardStatisticService,
    public location: Location,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.fetchProjectTimesheetStats();
    this.fetchEmployees();
  }

  fetchEmployees() {
    if (sessionStorage.getItem('employees')) {
      this.employees = JSON.parse(sessionStorage.getItem('employees'));
    } else {
      this.employeeService.fetchLiteEmployees().subscribe(res => {
        this.employees = res;
      });
    }
  }

  fetchProjectTimesheetStats() {
    
    var current = new Date(this.date.value);     // get current date    
    var weekstart = current.getDate();
    var weekend = weekstart - 6;       // end day is the first day + 6 
    this.startDate = dateTimeFormatters.formatDate(new Date(current.setDate(weekstart)));
    this.endDate = dateTimeFormatters.formatDate(new Date(current.setDate(weekend)));
    this.timesheetTaskDetail = [];
    this.timesheetDatesValue = [];
    this.detailsMap = new Map();
    this.projectTaskMap = new Map();

    if (this.selectedEmp.value != -1) {
      this.dashboardStatisticService.fetchProjectTimesheetStatsForEmp(this.endDate, this.startDate, this.selectedEmp.value).subscribe((res: TimesheetTaskDetailsModel[]) => {
        this.populateTable(res);
      });
    } else {
      this.dashboardStatisticService.fetchProjectTimesheetStats(this.endDate, this.startDate).subscribe((res: TimesheetTaskDetailsModel[]) => {
        this.populateTable(res);
      });
    }
  }

  timeConvert(val) {
    let h = Math.floor(val / 3600);
    val %= 3600;
    let m = Math.floor(val / 60);
    let s = (val % 60);

    var min = String(m).padStart(2, "0");
    var hr = String(h).padStart(2, "0");
    var sec = String(s).padStart(2, "0");
    return hr + ":" + min + ":" + sec;
  }

  populateTable(res) {
    this.timesheetTaskDetail = res;
    this.detailsMap = new Map<string, any>();
    this.dailyTotalHours = new Map<any, any>();
    for (const [key, value] of Object.entries(this.timesheetTaskDetail)) {
      var dateValue = [];
      var date = value.key;
      var details = value.value;
      for (let d of details) {
        var totalDuration = 0;
        var projectTaskMap = new Map<any, any>();
        if (!this.detailsMap.has(date)) {
          this.detailsMap.set(date, []);
        }
        var startTime = d.activityStartTime;
        var endTime = d.activityEndTime;
        totalDuration += ((endTime - startTime) / 1000);
        for (const [key, value] of Object.entries(d.projectTaskDetails)) {
          if (!projectTaskMap.has(value['key'])) {
            projectTaskMap.set(value['key'], new Map<any, any>());
          }
          var tasks = projectTaskMap.get(value['key']);
          var tempTasks = [];
          for (let t of value['value'].task) {
            tempTasks.push(t);
          }
          tasks['task']= tempTasks;

          var tempClient = [];
          for (let c of value['value'].client) {
            tempClient.push(c);
          }
          tasks['client']= tempClient;
          projectTaskMap[value['key']]= tasks;
        }
        dateValue.push({ 'start_time': startTime, 'end_time': endTime, 'projectDetails': projectTaskMap, 'duration': this.timeConvert((endTime - startTime) / 1000) });

        if (!this.dailyTotalHours.has(date)) {
          this.dailyTotalHours.set(date, 0);
        }
        var dailyTotalHours = this.dailyTotalHours.get(date) + ((endTime - startTime) / 1000);;
        this.dailyTotalHours.set(date, dailyTotalHours);
      }
      this.detailsMap.set(date, dateValue);
    }
    this.dailyTotalHours.forEach((value, key) => {
      value = this.timeConvert(value);
      this.dailyTotalHours.set(key, value);
    });
    if (!this.detailsMap.size) {
      this.showNoContentLabel = true;
    } else {
      this.showNoContentLabel = false;
    }
  }
  previousDay() {
    var currDate = this.date.value;
    this.date = new FormControl(new Date(currDate.setDate(currDate.getDate() - 1)));
    this.fetchProjectTimesheetStats();
  }

  nextDay() {
    var currDate = this.date.value;
    this.date = new FormControl(new Date(currDate.setDate(currDate.getDate() + 1)));
    this.fetchProjectTimesheetStats();
  }

  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }
}