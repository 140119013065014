import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormArray, FormGroup } from '@angular/forms';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { CompensatoryRequestBuilder, CompensatoryRequestModel } from 'src/app/shared/models/ewh-request-model';
import { CompensatoryService } from 'src/app/core/services/ewh.service';
import { HelpService } from 'src/app/core/services/help.service';


@Component({
  selector: 'app-my-extra-work-hours',
  templateUrl: './my-extra-work-hours.component.html',
  styleUrls: ['./my-extra-work-hours.component.css']
})
export class MyCompensatoryComponent implements OnInit {

  compensatoryReq: CompensatoryRequestBuilder = new CompensatoryRequestBuilder();
  compensatoryModel: CompensatoryRequestModel = this.compensatoryReq.compensatoryRequestModel;
  compensatoryForm: FormGroup = this.compensatoryReq.compensatoryRequestForm;

  displayedColumns: string[] = ['id', 'date', 'entryTime', 'exitTime', 'projectName', 'tasks','compensation', 'managerCompensatoryRequestStatus','hrCompensatoryRequestStatus','totalHours'];
  dataSource: MatTableDataSource<CompensatoryRequestModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private compensatoryRequestService: CompensatoryService,
    private helpService: HelpService,
    public messageService: MessageService,
    public router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.fetchEncashmentCompensatory();
    this.dataSource = new MatTableDataSource<CompensatoryRequestModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchEncashmentCompensatory() {
    this.compensatoryRequestService.fetchEncashmentCompensatory().subscribe((res: CompensatoryRequestModel[]) => {
      this.dataSource = new MatTableDataSource<CompensatoryRequestModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }
  routeToSectionFieldsId(compensatoryRequestDataSource, id: number) {

    this.compensatoryRequestService.routeToSectionFieldsIds(compensatoryRequestDataSource);
    this.router.navigate(['/applyCompensatory', id]);
  }


  findHelpUrlPage() {
    const currentPage = "/#_2_2_leave_request";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
