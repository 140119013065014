import { Injectable } from "@angular/core";
import { HttpRequestService } from "../http/http-request.service";
import { Observable } from 'rxjs';
import { AttendanceLogStatusModel } from "src/app/shared/models/attendance-log-file-status-model";
import { ActivityLogFileUploadModel } from "src/app/shared/models/activity-log-file-upload-model";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
  })
  export class AttendanceLogService {

    private stateUrl = 'api/v1/attendance';
    httpMultipartFormData = {
        headers: new HttpHeaders(), // content-type header will be set automatically by req
      };
    constructor(
        private httpRequest: HttpRequestService,
        private http:HttpClient
      ) { }

    fetchLogFileStatus(): Observable<AttendanceLogStatusModel[]> {
        return this.httpRequest.get(this.stateUrl + '/get-attendance-logs');
      }

    addLogFile(formData: any) {
        return this.httpRequest.filePost(this.stateUrl +'/upload-log-file', formData);
      }
  }