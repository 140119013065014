import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CompensatoryRequestService } from 'src/app/core/services/ewh-request.service';
import { CompensatoryService } from 'src/app/core/services/ewh.service';
import { FinancialYearService } from 'src/app/core/services/financial-year.service';
import { HolidayListService } from 'src/app/core/services/holidays-list.service';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { UserService } from 'src/app/core/services/user.service';
import { dateTimeFormatters } from 'src/app/shared/factory/common.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { CompensatoryApplyRequestBuilder, CompensatoryApplyRequestModel } from 'src/app/shared/models/ewh-apply-request-model';
import { FinancialYearModel } from 'src/app/shared/models/financial-year-model';
import { HolidayFormBuilder, HolidayModel } from 'src/app/shared/models/holiday-model';
import { OrganizationModel } from 'src/app/shared/models/organization-model';
import { UserModel } from 'src/app/shared/models/user-model';

@Component({
  selector: 'app-apply-ewh',
  templateUrl: './apply-ewh.component.html',
  styleUrls: ['./apply-ewh.component.css']
})
export class ApplyCompensatoryComponent implements OnInit {


  holidayAddForm: HolidayFormBuilder = new HolidayFormBuilder();
  holidayModel: HolidayModel = {
    id: null,
    name: '',
    date: null,
    financialYearId: null
  };
  financialYears = [];
  dateTimeFormatters = dateTimeFormatters;

  compensatoryApplyReq: CompensatoryApplyRequestBuilder = new CompensatoryApplyRequestBuilder();
  compensatoryApplyModel: CompensatoryApplyRequestModel = this.compensatoryApplyReq.compensatoryApplyRequestModel;
  compensatoryApplyRequestForm: FormGroup = this.compensatoryApplyReq.compensatoryApplyRequestForm;

  dateFilter = (date: Date) => {
    const currentDate = new Date();
    const isWeekend = date.getDay() === 6 || date.getDay() === 0;
    return isWeekend && date <= currentDate;
  };

  // Set the date filter to disable weekdays and non-holidays
  dateFilterHolidays = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    const currentDate = new Date();
    const day = date.getDay();
    const isWeekend = day === 0 || day === 6;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const isHoliday = this.holidays?.some(holiday => holiday.date === formattedDate);
    return (isWeekend || isHoliday) && date <= currentDate;
  };


  approvers = [];
  selectedApprovers = this.approvers[1];
  dataSource = new MatTableDataSource<CompensatoryApplyRequestModel>();
  id;
  currentEntryTime;
  currentExitTime;
  isEdit: boolean = false;
  holidays: HolidayModel[];

  constructor(
    private compensatoryService: CompensatoryService,
    public location: Location,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private compensatoryRequestService: CompensatoryRequestService,
    private userService: UserService,
    private route: ActivatedRoute,
    private holidayService: HolidayListService,
    private financialYearService: FinancialYearService,
    private organizationService: OrganizationService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.userService.fetchCompensatoryRequestApproversLite().subscribe((res: UserModel[]) => {
      this.approvers = res;
    });
    this.fetchFinancialYear();
    var currentFinancialYearInstanceId = '';
    this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
      this.holidayModel.financialYearId = res[0].currentFinancialYearInstanceId;
      this.holidayService.fetchHolidays(this.holidayModel.financialYearId).subscribe((res: HolidayModel[]) => {
        this.holidays = res;
      });
    });


    if (this.route.snapshot.params['new'] !== 'new') {
      this.isEdit = true;
      this.compensatoryService.compensatoryFieldsDetails.subscribe((compensatoryApplyModel: any) => {
        this.currentEntryTime = compensatoryApplyModel.entryTime.split(":");
        this.currentEntryTime = this.currentEntryTime[0] + ":" + this.currentEntryTime[1];
        this.currentExitTime = compensatoryApplyModel.exitTime.split(":");
        this.currentExitTime = this.currentExitTime[0] + ":" + this.currentExitTime[1];
        this.compensatoryApplyRequestForm.patchValue({
          "id": compensatoryApplyModel.id,
          "date": (new Date(compensatoryApplyModel.date)).toISOString(),
          "entryTime": this.currentEntryTime,
          "exitTime": this.currentExitTime,
          "projectName": compensatoryApplyModel.projectName,
          "tasks": compensatoryApplyModel.tasks,
          "compensation": compensatoryApplyModel.compensation,
        })
      });
    }
  }
  fetchFinancialYear() {
    this.financialYearService.fetchFinancialYears().subscribe((res: FinancialYearModel[]) => {
      this.financialYears = res;
    });
  }

  addUpdateCompensatoryRequest() {
    if (this.compensatoryApplyModel.encashmentCompensatoryRequestId) {
      this.compensatoryRequestService.updateCompensatoryRequest(this.compensatoryApplyModel).subscribe((res) => {
        this.messageService.display('Compensatory request updated successful! Compensatory ID: ' + this.compensatoryApplyModel.encashmentCompensatoryRequestId, 'ok', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/myEncashmentCompensatory']);
      },
        (error) => {
          this.messageService.display(error.error, 'ok', 'error');
        }
      );
    } else {
      this.compensatoryRequestService.addCompensatoryRequest(this.compensatoryApplyModel).subscribe((res) => {
        this.messageService.display('Compensatory request is created successfully! Compensatory ID: ' + res, 'ok', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/myEncashmentCompensatory']);
      },
        (error) => {
          if (error.error && error.error.errors && error.error.errors.encashmentCompensatoryRequestDTO) {
            const errorMessage = error.error.errors.encashmentCompensatoryRequestDTO[0].message;
            this.messageService.display(errorMessage, 'ok', 'error');
          } else {
            this.messageService.display('An error occurred while creating the compensatory request', 'ok', 'error');
          }
        })
    }
  }

  onChangeHour(event) {
  }
}
