import { Validators, FormGroup, FormControl } from '@angular/forms';

export interface ProjectIntegrationModel {
    project:
    {
        id: number;
        name: string;
    }
    integrationType: any
}

export class ProjectIntegrationFormBuilder {

    projectIntegrationModel: ProjectIntegrationModel = {
        project: null,
        integrationType: null
    };

    projectIntegrationForm: FormGroup = new FormGroup({
        projects: new FormControl(this.projectIntegrationModel.project, [Validators.required]),
        integrationTypes: new FormControl(this.projectIntegrationModel.integrationType, [Validators.required])
    });

    constructor() {
        this.projectIntegrationForm.valueChanges.subscribe(val => {
            this.projectIntegrationModel.project = val.projects;
            this.projectIntegrationModel.integrationType = val.integrationTypes;
        })
    }

    setModelVals(res: ProjectIntegrationModel) {
        this.projectIntegrationForm.controls.projects.setValue(res.project);
        this.projectIntegrationForm.controls.integrationTypes.setValue(res.integrationType);
    }
}