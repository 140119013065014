import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeModel } from 'src/app/shared/models/employee-model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private stateUrl = 'api/v1/employee/';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchLiteEmployees() : Observable<EmployeeModel[]> {
    return this.httpRequest.get(this.stateUrl + 'lite');
  }
}
