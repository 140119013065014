import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface DashboardStatisticModel {
    currentWeekSeconds : number,
    previousWeekSeconds : number,
    currentMonthSeconds : number,
    previousMonthSeconds : number,
    currentDaySeconds : number,
    previousDaySeconds : number,
    currentWeekActivity : number,
    previousWeekActivity : number,
    dailyStats : [{
        date : any,
        seconds : number
      }]
}




//import { customValidators } from '../factory/common.service';

export class DashboardStatisticBuilder {
    dashboardStatisticModel: DashboardStatisticModel = {
    currentWeekSeconds : null,
    previousWeekSeconds : null,
    currentMonthSeconds : null,
    previousMonthSeconds : null,
    currentDaySeconds : null,
    previousDaySeconds : null,
    currentWeekActivity : null,
    previousWeekActivity : null,
    dailyStats : [{
        date : '',
        seconds : null
      }]
    };

    dashboardStatisticForm: FormGroup = new FormGroup({
        //activityTimeLineId: new FormControl(this.activitySampleModel.activityTimeLineId),
        currentWeekSeconds : new FormControl(this.dashboardStatisticModel.currentWeekSeconds),
        previousWeekSeconds : new FormControl(this.dashboardStatisticModel.previousWeekSeconds),
        currentMonthSeconds : new FormControl(this.dashboardStatisticModel.currentMonthSeconds),
        previousMonthSeconds : new FormControl(this.dashboardStatisticModel.previousMonthSeconds),
        currentDaySeconds : new FormControl(this.dashboardStatisticModel.currentDaySeconds),
        previousDaySeconds : new FormControl(this.dashboardStatisticModel.previousDaySeconds),
        currentWeekActivity : new FormControl(this.dashboardStatisticModel.currentWeekActivity),
        previousWeekActivity : new FormControl(this.dashboardStatisticModel.previousWeekActivity),
        dailyStats : new FormControl(this.dashboardStatisticModel.dailyStats)
       
    });
    constructor() {
        this.dashboardStatisticForm.valueChanges.subscribe(val => {
            this.dashboardStatisticModel.currentWeekSeconds = val.currentWeekSeconds;
            this.dashboardStatisticModel.previousWeekSeconds = val.previousWeekSeconds;
            this.dashboardStatisticModel.currentMonthSeconds = val.currentMonthSeconds;
            this.dashboardStatisticModel.previousMonthSeconds = val.previousMonthSeconds;
            this.dashboardStatisticModel.currentDaySeconds = val.currentDaySeconds;
            this.dashboardStatisticModel.previousDaySeconds = val.previousDaySeconds;
            this.dashboardStatisticModel.currentWeekActivity = val.currentWeekActivity;
            this.dashboardStatisticModel.previousWeekActivity = val.previousWeekActivity;
            // this.dashboardStatisticModel.dailyStatistics.date = val.date;
            // this.dashboardStatisticModel.dailyStatistics.seconds = val.seconds;
            this.dashboardStatisticModel.dailyStats = val.dailyStats;
            
        });
    }
    setModelVals(res: DashboardStatisticModel) {
       this.dashboardStatisticForm.controls.currentWeekSeconds.setValue(res.currentWeekSeconds);
       this.dashboardStatisticForm.controls.previousWeekSeconds.setValue(res.previousWeekSeconds);
       this.dashboardStatisticForm.controls.currentMonthSeconds.setValue(res.currentMonthSeconds);
       this.dashboardStatisticForm.controls.previousMonthSeconds.setValue(res.previousMonthSeconds);
       this.dashboardStatisticForm.controls.currentDaySeconds.setValue(res.currentDaySeconds);
       this.dashboardStatisticForm.controls.previousDaySeconds.setValue(res.previousDaySeconds);
       this.dashboardStatisticForm.controls.currentWeekActivity.setValue(res.currentWeekActivity);
       this.dashboardStatisticForm.controls.previousWeekActivity.setValue(res.previousWeekActivity);
    //    this.dashboardStatisticForm.controls.date.setValue(res.dailyStatistics.date);
    //    this.dashboardStatisticForm.controls.seconds.setValue(res.dailyStatistics.seconds);
        this.dashboardStatisticForm.controls.dailyStats.setValue(res.dailyStats);
    }
}