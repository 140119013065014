import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/core/services/project.service';
import { ProjectIntegrationFormBuilder, ProjectIntegrationModel } from 'src/app/shared/models/project-integration-model';
import { FormGroup } from '@angular/forms';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { IntegrationModel } from 'src/app/shared/models/integration-model';
import { JiraIntegrationFormBuilder, JiraIntegrationModel } from 'src/app/shared/models/Jira-integration-model';
import { UserjiraintegrationdetailService } from 'src/app/core/services/user-jira-integration-detail.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { PivotalTrackerIntegrationFormBuilder, PivotalTrackerIntegrationModel } from 'src/app/shared/models/pivotal-tracker-model';
import { UserPivotalTrackerIntegrationDetailService } from 'src/app/core/services/user-pivotal-tracker-integration-detail.service';
import { GitlabIntegrationFormBuilder, GitlabIntegrationModel } from 'src/app/shared/models/gitlab-integration-model';
import { UserGitlabIntegrationDetailService } from 'src/app/core/services/user-gitlab-integration-detail.service';
import { GithubIntegrationFormBuilder, GithubIntegrationModel } from 'src/app/shared/models/github-integration-model';
import { UserGithubIntegrationDetailService } from 'src/app/core/services/user-github-integration-detail.service';

@Component({
  selector: 'app-project-integration',
  templateUrl: './project-integration.component.html',
  styleUrls: ['./project-integration.component.css']
})
export class ProjectIntegrationComponent implements OnInit {

  /**
   * Project integration Form group instance and modal instance for integration plateform
   */
  projectIntegration: ProjectIntegrationFormBuilder = new ProjectIntegrationFormBuilder();
  projectIntegrationModel: ProjectIntegrationModel = this.projectIntegration.projectIntegrationModel;
  projectIntegrationForm: FormGroup = this.projectIntegration.projectIntegrationForm;

  /**
   * Form group instance and modal instance for Jira Integration plateform
   */
  jiraIntegration: JiraIntegrationFormBuilder = new JiraIntegrationFormBuilder();
  jiraIntegrationModel: JiraIntegrationModel = this.jiraIntegration.jiraIntegrationModel;
  jiraIntegrationForm: FormGroup = this.jiraIntegration.jiraIntegrationForm;

  /**
   * Form group instance and modal instance for pivotal tracker Integration plateform
   */
  pivotalTrackerIntegration: PivotalTrackerIntegrationFormBuilder = new PivotalTrackerIntegrationFormBuilder();
  pivotalIntegrationModel: PivotalTrackerIntegrationModel = this.pivotalTrackerIntegration.pivotalTrackerIntegrationModel;
  pivotalTrackerIntegrationForm : FormGroup = this.pivotalTrackerIntegration.pivotalTrackerIntegrationForm;                                                        

  /**
   * Form group instance and modal instance for gitlab Integration plateform
   */
  gitlabIntegration: GitlabIntegrationFormBuilder = new GitlabIntegrationFormBuilder();
  gitlabIntegrationModel: GitlabIntegrationModel = this.gitlabIntegration.gitlabIntegrationModel;
  gitlabIntegrationForm : FormGroup = this.gitlabIntegration.gitlabIntegrationForm;                                                        

  /**
   * Form group instance and modal instance for github Integration plateform
   */
  githubIntegration: GithubIntegrationFormBuilder = new GithubIntegrationFormBuilder();
  githubIntegrationModel : GithubIntegrationModel = this.githubIntegration.githubIntegrationModel;
  githubIntegrationForm : FormGroup = this.githubIntegration.githubIntegrationForm; 
  
  /**
   * Variables to manage hide and show integration form for different platform
   */
  showJiraIntegrationForm:boolean=false;
  showPivotalIntegrationForm:boolean=false;
  showGitlabIntegrationForm:boolean=false;
  showGithubIntegrationForm:boolean=false;
  projects = [];
  integrationTypes = [];
  selectedIntegrationType=null;
  selectType = null; 

  constructor( 
    private projectService: ProjectService,
    private integrationService: IntegrationService,
    private jiraIntegrationDetailService: UserjiraintegrationdetailService,
    private messageService: MessageService,
    private pivotalTrackerIntegrationDetailService: UserPivotalTrackerIntegrationDetailService,
    private gitlabIntegrationDetailService: UserGitlabIntegrationDetailService,
    private githubIntegrationDetailService: UserGithubIntegrationDetailService
    )
   {}

  ngOnInit() {
    this.fetchProjects();
  }

  /**
   * Fetch all the projects allowed to current user
   */
  fetchProjects(){
    this.projectService.fetchProjects().subscribe((res) => {
      this.projects = res;
    });
  }

  /**
   *  This method is responsible for process diff integration platform input form 
   */
  addProjectIntegration(){

    switch (this.selectedIntegrationType) {
      case "JIRACLOUD":
        this.showJiraIntegrationForm = true;
        this.jiraIntegrationModel.project = this.projectIntegrationModel.project;
        this.projectIntegrationExist(this.selectedIntegrationType);
        break;
      case "PIVOTALTRACKER":
        this.showPivotalIntegrationForm = true;
        this.pivotalIntegrationModel.project = this.projectIntegrationModel.project;
        this.projectIntegrationExist(this.selectedIntegrationType);
        break;
      case "GITLAB":
        this.showGitlabIntegrationForm = true;
        this.gitlabIntegrationModel.project = this.projectIntegrationModel.project;
        this.projectIntegrationExist(this.selectedIntegrationType);
        break;
      case "GITHUB":
        this.showGithubIntegrationForm = true;
        this.githubIntegrationModel.project = this.projectIntegrationModel.project;
        this.projectIntegrationExist(this.selectedIntegrationType);
        break;
      default:
        break;
    }
  }

  /**
   * 
   * @param $event 
   */
  changeIntegrationType($event) {
    this.showJiraIntegrationForm = false;
    this.showPivotalIntegrationForm = false;
    this.showGitlabIntegrationForm = false;
    this.showGithubIntegrationForm = false;
    this.selectedIntegrationType = $event;
  }

  /**
   * This method is responsible for fetch integration list based on project change
   * @param $event 
   */
  changeProject($event){
    this.showJiraIntegrationForm = false;
    this.showPivotalIntegrationForm = false;
    this.showGitlabIntegrationForm = false;
    this.showGithubIntegrationForm = false;
    this.integrationService.fetchIntegrations(this.projectIntegrationModel.project.id).subscribe((res:IntegrationModel) => {
        this.integrationTypes = res.integrationTypes;
    });
  }

  /**
   * This method is resposible for check existing integration of project based on project details
   */
  projectIntegrationExist(selectedIntegrationType:String) {

    switch (this.selectedIntegrationType) {
      case "JIRACLOUD":
        this.jiraIntegrationDetailService.checkIntegrationExist(this.jiraIntegrationModel.project.id).subscribe((res) => {
          console.log(res);
          if (res) {
            this.messageService.display('Jira Project Integration Exist !', 'OK', 'success');
            this.jiraIntegrationDetailService.fetchIntegrationDetails(this.jiraIntegrationModel.project.id).subscribe((res: JiraIntegrationModel) => {
              this.jiraIntegration.setModelVals(res);
            });
          }
          else {
            this.jiraIntegrationForm.reset();
          }
        });
        break;
      case "PIVOTALTRACKER":
        this.pivotalTrackerIntegrationDetailService.checkIntegrationExist(this.pivotalIntegrationModel.project.id).subscribe((res) => {
          console.log(res);
          if (res) {
            this.messageService.display('Pivotal Tracker Project Integration Exist !', 'OK', 'success');
            this.pivotalTrackerIntegrationDetailService.fetchIntegrationDetails(this.pivotalIntegrationModel.project.id).subscribe((res: PivotalTrackerIntegrationModel) => {
              this.pivotalTrackerIntegration.setModelVals(res);
            });
          }
          else {
            this.pivotalTrackerIntegrationForm.reset();
          }
        });
        break;
      case "GITLAB":
        this.gitlabIntegrationDetailService.checkIntegrationExist(this.gitlabIntegrationModel.project.id).subscribe((res) => {
          if (res) {
            this.messageService.display('Gitlab Project Integration Exist !', 'OK', 'success');
            this.gitlabIntegrationDetailService.fetchIntegrationDetails(this.gitlabIntegrationModel.project.id).subscribe((res: GitlabIntegrationModel) => {
              this.gitlabIntegration.setModelVals(res);
            });
          }
          else {
            this.gitlabIntegrationForm.reset();
          }
        });
        break;
      case "GITHUB":
        this.githubIntegrationDetailService.checkIntegrationExist(this.githubIntegrationModel.project.id).subscribe((res) => {
          if (res) {
            this.messageService.display('Gitlab Project Integration Exist !', 'OK', 'success');
            this.githubIntegrationDetailService.fetchIntegrationDetails(this.githubIntegrationModel.project.id).subscribe((res: GithubIntegrationModel) => {
              this.githubIntegration.setModelVals(res);
            });
          }
          else {
            this.githubIntegrationForm.reset();
          }
        });
        break;
      default:
        break;
    }
  }

   /**
   * This method is responsible for handle jira integration forms
   */
  submitJiraForm(){
    this.jiraIntegrationModel.project = this.projectIntegrationModel.project;
    if (this.jiraIntegrationModel.id) {
      this.jiraIntegrationDetailService.updateJiraIntegrationDetail(this.jiraIntegrationModel).subscribe((res) => {
        this.messageService.display('Project Integration updated successfully ! Jira Integration ID : ' + res, 'OK', 'success');
        this.jiraIntegrationForm.reset();
        this.showJiraIntegrationForm = false;
      });   
    }
    else{
    this.jiraIntegrationDetailService.addJiraIntegrationDetail(this.jiraIntegrationModel).subscribe((res) => {
      this.messageService.display('Project Integrated successfully ! Jira Integration ID : ' + res, 'OK', 'success');
      this.jiraIntegrationForm.reset();
      this.showJiraIntegrationForm = false;
    });
  }
  }

  /**
   * This method is responsible for handle pivotal tracker integration form
   */
  submitPivotalTrackerForm(){
    this.pivotalIntegrationModel.project = this.projectIntegrationModel.project;
       if (this.pivotalIntegrationModel.id) {
      this.pivotalTrackerIntegrationDetailService.updatePivotalTrackerIntegrationDetail(this.pivotalIntegrationModel).subscribe((res) => {
        this.messageService.display('Project Integration updated successfully ! Pivotal  Integration ID : ' + res, 'OK', 'success');
        this.pivotalTrackerIntegrationForm.reset();
        this.showPivotalIntegrationForm = false;
      });   
     }
    else{
    this.pivotalTrackerIntegrationDetailService.addPivotalTrackerIntegrationDetail(this.pivotalIntegrationModel).subscribe((res) => {
      this.messageService.display('Project Integrated successfully ! Pivotal Integration ID : ' + res, 'OK', 'success');
      this.pivotalTrackerIntegrationForm.reset();
      this.showPivotalIntegrationForm = false;
    });
  }
  }

  /**
   * This method is responsible for handle gitlab integration form
   */
  submitGitlabForm(){
    this.gitlabIntegrationModel.project = this.projectIntegrationModel.project;
       if (this.gitlabIntegrationModel.id) {
      this.gitlabIntegrationDetailService.updateGitlabIntegrationDetail(this.gitlabIntegrationModel).subscribe((res) => {
        this.messageService.display('Project Integration updated successfully ! Gitlab  Integration ID : ' + res, 'OK', 'success');
        this.gitlabIntegrationForm.reset();
        this.showGitlabIntegrationForm = false;
      });   
     }
    else{
    this.gitlabIntegrationDetailService.addGitlabIntegrationDetail(this.gitlabIntegrationModel).subscribe((res) => {
      this.messageService.display('Project Integrated successfully ! Gitlab Integration ID : ' + res, 'OK', 'success');
      this.gitlabIntegrationForm.reset();
      this.showGitlabIntegrationForm = false;
    });
  }
    
  }

   /**
   * This method is responsible for handle github integration forms
   */
  submitGithubForm(){
    this.githubIntegrationModel.project = this.projectIntegrationModel.project;
    if (this.githubIntegrationModel.id) {
      this.githubIntegrationDetailService.updateGithubIntegrationDetail(this.githubIntegrationModel).subscribe((res) => {
        this.messageService.display('Project Integration updated successfully ! Github Integration ID : ' + res, 'OK', 'success');
        this.githubIntegrationForm.reset();
        this.showGithubIntegrationForm = false;
      });   
    }
    else{
    this.githubIntegrationDetailService.addGithubIntegrationDetail(this.githubIntegrationModel).subscribe((res) => {
      this.messageService.display('Project Integrated successfully ! Github Integration ID : ' + res, 'OK', 'success');
      this.githubIntegrationForm.reset();
      this.showGithubIntegrationForm = false;
    });
  }
  }
  
}
