import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CompensatoryRequestApproverService } from 'src/app/core/services/ewh-request-approver.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { CompensatoryRequestActionBuilder, CompensatoryRequestActionModel } from 'src/app/shared/models/ewh-request-action-model';
import { CompensatoryActionRequestComponent } from 'src/app/shared/popups/ewh-popups';

@Component({
  selector: 'app-ewh-action',
  templateUrl: './ewh-action.component.html',
  styleUrls: ['./ewh-action.component.css']
})
export class CompensatoryActionComponent implements OnInit {
  compensatoryActionReq: CompensatoryRequestActionBuilder = new CompensatoryRequestActionBuilder();
  compensatoryActionModel: CompensatoryRequestActionModel = this.compensatoryActionReq.compensatoryRequestActionModel;
  compensatoryActionForm: FormGroup = this.compensatoryActionReq.compensatoryRequestActionForm;

  displayedColumns = ['id', 'employeeLite','date', 'entryTime', 'exitTime','compensation','managerCompensatoryRequestStatus','hrCompensatoryRequestStatus','totalHours','action']
  dataSource: MatTableDataSource<CompensatoryRequestActionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  employees = [];
  param1: number;

  constructor(
    private compensatoryRequestApproverService: CompensatoryRequestApproverService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private loaderService: LoaderService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.param1 = params['encashmentCompensatoryRequestId'];
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<CompensatoryRequestActionModel>([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.fetchAllPendingCompensatoryRequest();
    this.paginator.page.subscribe(options => {
      this.fetchAllPendingCompensatoryRequest();
    });
  }

  fetchAllPendingCompensatoryRequest() {
    this.compensatoryRequestApproverService.fetchAllPendingCompensatoryRequest().subscribe((res: CompensatoryRequestActionModel[]) => {
      this.dataSource = new MatTableDataSource<CompensatoryRequestActionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openCompensatoryActionDialog(encashmentCompensatoryRequestId, userId, approverActionComment,hrActionComment, salary,encashmentCompensatoryRequestApprovers, managerCompensatoryRequestStatus,hrCompensatoryRequestStatus) {
    this.compensatoryActionModel.encashmentCompensatoryRequestId = encashmentCompensatoryRequestId;
    this.compensatoryActionModel.userId = userId;
    this.compensatoryActionModel.approverActionComment = approverActionComment;
    this.compensatoryActionModel.encashmentCompensatoryRequestApprovers = encashmentCompensatoryRequestApprovers;
    this.compensatoryActionModel.managerCompensatoryRequestStatus = managerCompensatoryRequestStatus;
    this.compensatoryActionModel.hrCompensatoryRequestStatus = hrCompensatoryRequestStatus;
    this.compensatoryActionModel.hrActionComment = hrActionComment;
    this.compensatoryActionModel.salary = salary;


    const dialogRef = this.dialog.open(CompensatoryActionRequestComponent, {
      data: { encashmentCompensatoryRequestId: encashmentCompensatoryRequestId, userId, encashmentCompensatoryRequestApprovers: encashmentCompensatoryRequestApprovers, managerCompensatoryRequestStatus: managerCompensatoryRequestStatus,hrCompensatoryRequestStatus: hrCompensatoryRequestStatus }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.fetchAllPendingCompensatoryRequest();
        this.loaderService.updateInboxAction(true);
      }
    });
  }
}

