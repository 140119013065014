import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface GitlabIntegrationModel {
    id: number;
    personalAccessToken: any
    project: {
        id: number,
        name: string
    },
}


export class GitlabIntegrationFormBuilder {

    gitlabIntegrationModel: GitlabIntegrationModel = {
        id: null,
        personalAccessToken: null,
        project: null,
    };

    gitlabIntegrationForm: FormGroup = new FormGroup({
        personalAccessToken: new FormControl(this.gitlabIntegrationModel.personalAccessToken, [Validators.required]),
        id: new FormControl(this.gitlabIntegrationModel.id)
    });

    constructor() {
        this.gitlabIntegrationForm.valueChanges.subscribe(val => {
            this.gitlabIntegrationModel.personalAccessToken = val.personalAccessToken;
            this.gitlabIntegrationModel.id = val.id;
        })
    }
    setModelVals(res: GitlabIntegrationModel) {
        this.gitlabIntegrationForm.controls.personalAccessToken.setValue(res.personalAccessToken);
        this.gitlabIntegrationForm.controls.id.setValue(res.id);
    }
}

