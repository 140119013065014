import { FormControl, FormGroup } from "@angular/forms";


export interface CompensatoryApplyRequestModel {
    encashmentCompensatoryRequestId: number,
    encashmentCompensatoryType: string,
    projectName: string,
    encashmentCompensatoryRequestApprovers: any,
    date: string,
    entryTime: string,
    exitTime: string,
    approverActionComment: string | null,
    tasks: string,
    compensation: string
}

export class CompensatoryApplyRequestBuilder{
    compensatoryApplyRequestModel : CompensatoryApplyRequestModel={
        encashmentCompensatoryRequestId : null,
        encashmentCompensatoryType: '',
        projectName: '',
        encashmentCompensatoryRequestApprovers: [],
        date: '',
        entryTime: '',
        exitTime:'',
        approverActionComment: null,
        tasks: '',
        compensation:''
    };

    compensatoryApplyRequestForm: FormGroup = new FormGroup({
        encashmentCompensatoryRequestId:new FormControl(this.compensatoryApplyRequestModel.encashmentCompensatoryRequestId),
        encashmentCompensatoryType: new FormControl(this.compensatoryApplyRequestModel.encashmentCompensatoryType),
        projectName:new FormControl(this.compensatoryApplyRequestModel.projectName),
        encashmentCompensatoryRequestApprovers:new FormControl(this.compensatoryApplyRequestModel.encashmentCompensatoryRequestApprovers),
        date:new FormControl(this.compensatoryApplyRequestModel.date),
        entryTime:new FormControl(this.compensatoryApplyRequestModel.entryTime),
        exitTime:new FormControl(this.compensatoryApplyRequestModel.exitTime),
        approverActionComment:new FormControl(this.compensatoryApplyRequestModel.approverActionComment),
        tasks:new FormControl(this.compensatoryApplyRequestModel.tasks),
        compensation:new FormControl(this.compensatoryApplyRequestModel.compensation)

    })

    constructor(){
        this.compensatoryApplyRequestForm.valueChanges.subscribe(val =>{
            this.compensatoryApplyRequestModel.encashmentCompensatoryRequestId = val.encashmentCompensatoryRequestId;
            this.compensatoryApplyRequestModel.encashmentCompensatoryType = val.ewhType;
            this.compensatoryApplyRequestModel.projectName = val.projectName;
            this.compensatoryApplyRequestModel.encashmentCompensatoryRequestApprovers = val.encashmentCompensatoryRequestApprovers;
            this.compensatoryApplyRequestModel.date = val.date;
            this.compensatoryApplyRequestModel.entryTime = val.entryTime;
            this.compensatoryApplyRequestModel.exitTime = val.exitTime;
            this.compensatoryApplyRequestModel.approverActionComment = val.approverActionComment;
            this.compensatoryApplyRequestModel.tasks = val.tasks;
            this.compensatoryApplyRequestModel.compensation = val.compensation;

        });
    }
    setModelVals(res:CompensatoryApplyRequestModel){
        this.compensatoryApplyRequestForm.controls.encashmentCompensatoryRequestId.setValue(res.encashmentCompensatoryRequestId);
        this.compensatoryApplyRequestForm.controls.ewhType.setValue(res.encashmentCompensatoryType);
        this.compensatoryApplyRequestForm.controls.projectName.setValue(res.projectName);
        this.compensatoryApplyRequestForm.controls.encashmentCompensatoryRequestApprovers.setValue(res.encashmentCompensatoryRequestApprovers);
        this.compensatoryApplyRequestForm.controls.date.setValue(res.date);
        this.compensatoryApplyRequestForm.controls.entryTime.setValue(res.entryTime);
        this.compensatoryApplyRequestForm.controls.exitTime.setValue(res.exitTime);
        this.compensatoryApplyRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.compensatoryApplyRequestForm.controls.tasks.setValue(res.tasks);
        this.compensatoryApplyRequestForm.controls.compensation.setValue(res.compensation);

    }
}