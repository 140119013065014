import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HolidayModel } from 'src/app/shared/models/holiday-model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayListService {
  private stateUrl = 'api/v1/holiday';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchHolidays(financialYearId): Observable<HolidayModel[]> {
    return this.httpRequest.get(this.stateUrl + '/' + financialYearId);
  }
}