import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { TaskModel } from 'src/app/shared/models/task-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private stateUrl = 'api/v1/task';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchTasks(): Observable<TaskModel[]> {
    return this.httpRequest.get(this.stateUrl + '/task-list');
  }

  fetchTaskById(taskId:number): Observable<TaskModel> {
    return this.httpRequest.get(this.stateUrl + '/' + taskId);
  }

  updateTask(taskModel: TaskModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-task', taskModel);
  }

  addTask(taskModel: TaskModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/add-task', taskModel);
  }

  /**
   * This service update task status to Closed as of now
   * @param taskModel 
   */
  updateTaskStatus(taskModel: TaskModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-task-status',taskModel);
  }

  /**
   * This service method is responsible for fetch all tasks based on project details
   * @param projectId 
   */
  findTasksByProjectId(projectId:number): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/taskList/'+projectId);
  }


}
