import { Validators, FormGroup, FormControl } from '@angular/forms';
import { customValidators } from '../factory/common.service';

export interface JiraIntegrationModel {
    id: number;
    personalAccessToken:any
    project: {
        id:number,
        name:string
    },
    email:any
}

export class JiraIntegrationFormBuilder {

    jiraIntegrationModel: JiraIntegrationModel = {
        id: null,
        personalAccessToken:null,
        project: null,
        email:null
    };

    jiraIntegrationForm : FormGroup = new FormGroup({
        personalAccessToken: new FormControl(this.jiraIntegrationModel.personalAccessToken, [Validators.required]),
        id: new FormControl(this.jiraIntegrationModel.id, [Validators.required]),
        email:  new FormControl(this.jiraIntegrationModel.email,[Validators.required, customValidators.emailValidator]),
    });

    constructor() {
        this.jiraIntegrationForm.valueChanges.subscribe(val => {
            this.jiraIntegrationModel.personalAccessToken = val.personalAccessToken;
            this.jiraIntegrationModel.id = val.id;
            this.jiraIntegrationModel.email = val.email;
        })
    }
   setModelVals(res: JiraIntegrationModel) {
        this.jiraIntegrationForm.controls.personalAccessToken.setValue(res.personalAccessToken);
        this.jiraIntegrationForm.controls.id.setValue(res.id);
        this.jiraIntegrationForm.controls.email.setValue(res.email);
    }


}