import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompensatoryService {
  private stateUrl = 'api/v1/compensatory-request';
  public compensatoryFieldsDetails = new BehaviorSubject<any>(null);


  constructor(private httpRequest: HttpRequestService) { }

  fetchEncashmentCompensatory() {
    return this.httpRequest.get(this.stateUrl);
  }

  routeToSectionFieldsIds(compensatoryRequestActionModel) {
    this.compensatoryFieldsDetails.next(compensatoryRequestActionModel);
  }

}
