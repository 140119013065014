import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AttendanceLogService } from 'src/app/core/services/AttendenceLogService';
import { MessageService } from 'src/app/shared/factory/message.service';
import { AttendanceLogStatusModel } from 'src/app/shared/models/attendance-log-file-status-model';

@Component({
  selector: 'app-attendance-log-file',
  templateUrl: './attendance-log-file.component.html',
  styleUrls: ['./attendance-log-file.component.css']
})
export class AttendanceLogFileComponent implements OnInit {

 selectedFileName = "";
 logFileStatuses = [];
 @ViewChild(MatPaginator) paginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;
 
 document: any = null;
 //activityLogFileUploadModel: ActivityLogFileUploadModel = this.activityLogDocument.activityLogFileUploadModel;
 displayedColumns: string[] = ['id', 'fileUploadPath', 'userCreatedBy', 'createdDate', 'fileUploadStatus'];
 dataSource: MatTableDataSource<AttendanceLogStatusModel>;
 @ViewChild("fileInput", {static: false})
 InputVar: ElementRef;
 constructor(
    private attendanceLogService: AttendanceLogService,
    private messageService:MessageService,
    
 ) {} 
  

  ngOnInit(){
    this.fetchActivityLogStatus();
    this.dataSource = new MatTableDataSource<AttendanceLogStatusModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addLogDocument() {
    let formData: FormData = new FormData();
    formData.append('file',this.selectedFileName);
    this.attendanceLogService.addLogFile(formData).subscribe((response: any) => {
        this.messageService.display('File uploaded succesfully!!', 'OK', 'Success');
        this.fetchActivityLogStatus();
        this.InputVar.nativeElement.value = "";
    }, 
    (error: any) => {
        console.log('r',error)
    });
  }

  onFileInput($event) {
    if ($event.srcElement.files.length > 0 && $event.srcElement.files[0].name.toLowerCase().endsWith('.txt')) {
      this.selectedFileName = $event.target.files[0];
      this.addLogDocument();
    }
  }

  fetchActivityLogStatus() {
    this.attendanceLogService.fetchLogFileStatus().subscribe((res: AttendanceLogStatusModel[]) => {
      this.logFileStatuses = res;
      this.dataSource = new MatTableDataSource<AttendanceLogStatusModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
