import { Validators, FormGroup, FormControl } from '@angular/forms';

export interface PivotalTrackerIntegrationModel {
    id: number;
    personalAccessToken:any
    project: {
        id:number,
        name:string
    },
}

export class PivotalTrackerIntegrationFormBuilder {

    pivotalTrackerIntegrationModel: PivotalTrackerIntegrationModel = {
        id: null,
        personalAccessToken:null,
        project: null,
    };

    pivotalTrackerIntegrationForm : FormGroup = new FormGroup({
        personalAccessToken: new FormControl(this.pivotalTrackerIntegrationModel.personalAccessToken, [Validators.required]),
        id: new FormControl(this.pivotalTrackerIntegrationModel.id)
    });

    constructor() {
        this.pivotalTrackerIntegrationForm.valueChanges.subscribe(val => {
            this.pivotalTrackerIntegrationModel.personalAccessToken = val.personalAccessToken;
            this.pivotalTrackerIntegrationModel.id = val.id;
        })
    }
   setModelVals(res: PivotalTrackerIntegrationModel) {
        this.pivotalTrackerIntegrationForm.controls.personalAccessToken.setValue(res.personalAccessToken);
        this.pivotalTrackerIntegrationForm.controls.id.setValue(res.id);
    }
}