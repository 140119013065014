import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface CompensatoryRequestModel{
    id: number,
    compensatoryApprover:  Array<any>,
    entryTime: string,
    exitTime: string,
    date: string,
    projectName: string,
    tasks: string,
    encashmentCompensatoryRequestApprovers: Array<any>,
    employeeDTOLite: {
        id: number,
        firstName: string,
        lastName: string,
        fullName: String,
    }
    approverActionComment: string,
    hrActionComment: string,
    managerCompensatoryRequestStatus: string,
    hrCompensatoryRequestStatus: string,
    encashmentCompensatoryType: string,
    salary: number,
    totalHours: number,

    compensation : string

}

export class CompensatoryRequestBuilder{
    compensatoryRequestModel : CompensatoryRequestModel ={
        id: null,
        compensatoryApprover:  [],
        entryTime: '',
        exitTime: '',
        date: '',
        projectName: '',
        tasks: '',
        employeeDTOLite: {
            id: null,
            firstName: '',
            lastName: '',
            fullName: ''
        },
        approverActionComment: '',
        hrActionComment: '',
        managerCompensatoryRequestStatus : '',
        hrCompensatoryRequestStatus: '',
        encashmentCompensatoryType : '',
        compensation : '',
        salary : null,
        totalHours : null,

        encashmentCompensatoryRequestApprovers: []
    };

    compensatoryRequestForm: FormGroup =new FormGroup({
        employeeDTOLite: new FormGroup({
            id: new FormControl(this.compensatoryRequestModel.employeeDTOLite.id),
            firstName: new FormControl(this.compensatoryRequestModel.employeeDTOLite.firstName),
            lastName: new FormControl(this.compensatoryRequestModel.employeeDTOLite.lastName),
            fullName: new FormControl(this.compensatoryRequestModel.employeeDTOLite.firstName + this.compensatoryRequestModel.employeeDTOLite.lastName),
        }),
        entryTime:new FormControl(this.compensatoryRequestModel.entryTime,[Validators.required]),
        exitTime:new FormControl(this.compensatoryRequestModel.exitTime,[Validators.required]),
        date:new FormControl(this.compensatoryRequestModel.date,[Validators.required]),
        projectName:new FormControl(this.compensatoryRequestModel.projectName,[Validators.required]),
        tasks:new FormControl(this.compensatoryRequestModel.tasks,[Validators.required]),
        ewhType:new FormControl(this.compensatoryRequestModel.encashmentCompensatoryType,[Validators.required]),
        managerCompensatoryRequestStatus:new FormControl(this.compensatoryRequestModel.managerCompensatoryRequestStatus,[Validators.required]),
        hrCompensatoryRequestStatus:new FormControl(this.compensatoryRequestModel.hrCompensatoryRequestStatus,[Validators.required]),
        encashmentCompensatoryRequestApprovers: new FormControl(this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers,[Validators.required]),
        approverActionComment: new FormControl(this.compensatoryRequestModel.approverActionComment, [Validators.required]),
        hrActionComment: new FormControl(this.compensatoryRequestModel.hrActionComment, [Validators.required]),
        compensation: new FormControl(this.compensatoryRequestModel.compensation, [Validators.required]),
        compensatoryApprover: new FormControl(this.compensatoryRequestModel.compensatoryApprover, [Validators.required]),
        salary: new FormControl(this.compensatoryRequestModel.salary, [Validators.required]),
        totalHours: new FormControl(this.compensatoryRequestModel.totalHours, [Validators.required]),

    });

    constructor(){
        this.compensatoryRequestForm.valueChanges.subscribe(val =>{
            this.compensatoryRequestModel.employeeDTOLite = val.employeeDTOLite;
           this.compensatoryRequestModel.entryTime=val.entryTime;
           this.compensatoryRequestModel.exitTime=val.exitTime;
           this.compensatoryRequestModel.date=val.date;
           this.compensatoryRequestModel.projectName=val.projectName;
           this.compensatoryRequestModel.tasks=val.tasks;
           this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers=val.encashmentCompensatoryRequestApprovers;
           this.compensatoryRequestModel.approverActionComment = val.approverActionComment;
           this.compensatoryRequestModel.hrActionComment = val.hrActionComment;
           this.compensatoryRequestModel.managerCompensatoryRequestStatus=val.managerCompensatoryRequestStatus;
           this.compensatoryRequestModel.hrCompensatoryRequestStatus=val.hrCompensatoryRequestStatus;
           this.compensatoryRequestModel.encashmentCompensatoryType=val.ewhType;
           this.compensatoryRequestModel.compensation=val.compensation;
           this.compensatoryRequestModel.compensatoryApprover=val.compensatoryApprover;
           this.compensatoryRequestModel.salary=val.salary;
           this.compensatoryRequestModel.totalHours=val.totalHours;


        });
    }
    setModelVals(res:CompensatoryRequestModel){
        this.compensatoryRequestForm.controls.entryTime.setValue(res.entryTime);
        this.compensatoryRequestForm.controls.exitTime.setValue(res.exitTime);
        this.compensatoryRequestForm.controls.date.setValue(res.date);
        this.compensatoryRequestForm.controls.projectName.setValue(res.projectName);
        this.compensatoryRequestForm.controls.tasks.setValue(res.tasks);
        this.compensatoryRequestForm.controls.encashmentCompensatoryRequestApprovers.setValue(res.encashmentCompensatoryRequestApprovers);
        this.compensatoryRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.compensatoryRequestForm.controls.hrActionComment.setValue(res.hrActionComment);
        this.compensatoryRequestForm.controls.managerCompensatoryRequestStatus.setValue(res.managerCompensatoryRequestStatus);
        this.compensatoryRequestForm.controls.hrCompensatoryRequestStatus.setValue(res.hrCompensatoryRequestStatus);
        this.compensatoryRequestForm.controls.ewhType.setValue(res.encashmentCompensatoryType);
        this.compensatoryRequestForm.controls.compensation.setValue(res.compensation);
        this.compensatoryRequestForm.controls.compensatoryApprover.setValue(res.compensatoryApprover);
        this.compensatoryRequestForm.controls.salary.setValue(res.salary);
        this.compensatoryRequestForm.controls.totalHours.setValue(res.totalHours);


        let employeeDetailFormGroup = this.compensatoryRequestForm.get('employeeDTOLite') as FormGroup;
        
        employeeDetailFormGroup.controls.id.setValue(res.employeeDTOLite.id);
        employeeDetailFormGroup.controls.firstName.setValue(res.employeeDTOLite.firstName);
        employeeDetailFormGroup.controls.lastName.setValue(res.employeeDTOLite.lastName);
        employeeDetailFormGroup.controls.fullName.setValue(res.employeeDTOLite.firstName + res.employeeDTOLite.lastName);

    }
}