import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ɵConsole } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { DashboardStatisticBuilder, DashboardStatisticModel } from '../../shared/models/dashboard-statistic-model';
import { ProjectHourlyStatsBuilder, ProjectHourlyStatsModel } from '../../shared/models/project-hourly-stats-model';
import { DailyProjectStatisticsBuilder, DailyProjectStatisticsModel } from '../../shared/models/daily-project-statistics-model';
import { DashboardStatisticService } from '../../core/services/dashboard-statistic.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { dateTimeFormatters } from '../../shared/factory/common.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  displayedColumns = ['projectName', 'date', 'startTime', 'endTime', 'duration'];
  dataSource: MatTableDataSource<DailyProjectStatisticsModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  dashboardStatistic: DashboardStatisticBuilder = new DashboardStatisticBuilder();
  dashboardStatisticModel: DashboardStatisticModel = this.dashboardStatistic.dashboardStatisticModel;
  dashboardStatisticForm: FormGroup = this.dashboardStatistic.dashboardStatisticForm;

  projectHourlyStats: ProjectHourlyStatsBuilder = new ProjectHourlyStatsBuilder();
  projectHourlyStatsModel: ProjectHourlyStatsModel = this.projectHourlyStats.projectHourlyStatsModel;
  projectHourlyStatsForm: FormGroup = this.projectHourlyStats.projectHourlyStatsForm;

  dailyProjectStatistics: DailyProjectStatisticsBuilder = new DailyProjectStatisticsBuilder();
  dailyProjectStatisticsModel: DailyProjectStatisticsModel = this.dailyProjectStatistics.dailyProjectStatisticsModel;
  dailyProjectStatisticsForm: FormGroup = this.dailyProjectStatistics.dailyProjectStatisticsForm;


  iconForWeekHours = {'icon' : 'arrow_draw_up', 'color' : 'green'};
  iconForMonthHours = {'icon' : 'arrow_draw_up', 'color' : 'green'};
  iconForDayHours = {'icon' : 'arrow_draw_up', 'color' : 'green'};
  iconForCurrentWeekActivityHours = {'icon' : 'arrow_draw_up', 'color' : 'green'};
  weekHoursDiff = null;
  monthHoursDiff = null;
  dayHoursDiff = null;
  currentWeekActivityDiff = null;
  map: Map<string, number> = new Map<string, number>();
  projectMap: Map<string, any> = new Map<string, any>();
  // dataSource: MatTableDataSource<>;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  dailyStatistics = [];
  weeklyProjectStats = [];
  totalHours = 0;
  DailyProjectStatistics = [];
  barVerticalOptions1 = {

    single: [],
    view: [347, 347],
    showXAxis: true, // options
    showYAxis: true,
    gradient: false,
    showLegend: false,
    showXAxisLabel: true,
    xAxisLabel: 'days',
    showYAxisLabel: true,
    yAxisLabel: 'hours',
    colorScheme: {
      domain: ['#000000', '#cccccc', '#AAAAAA']
    }
  };

  constructor(
    private dashboardStatisticService: DashboardStatisticService
  ) { }

  ngOnInit() {

    this.fetchDashboardStatistics();
    this.fetchWeeklyProjectStatistics();
    this.fetchDailyProjectStatistics();
    this.dataSource = new MatTableDataSource<DailyProjectStatisticsModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onSelect(event) {
  }


  fetchDashboardStatistics() {
    this.dashboardStatisticService.fetchDashboardStatistic().subscribe((res: DashboardStatisticModel) => {
      // this.dashboardStatisticModel.currentDaySeconds = parseFloat((res.currentDaySeconds / 3600).toFixed(2));
      this.dashboardStatisticModel.currentDaySeconds = res.currentDaySeconds; //parseFloat((res.currentDaySeconds / 3600).toFixed(1));
      this.dashboardStatisticModel.currentMonthSeconds = res.currentMonthSeconds; //parseFloat((res.currentMonthSeconds / 3600).toFixed(1));
      this.dashboardStatisticModel.currentWeekSeconds = res.currentWeekSeconds; //parseFloat((res.currentWeekSeconds / 3600).toFixed(1));
      this.dashboardStatisticModel.currentWeekActivity = parseFloat((((100 * res.currentWeekSeconds) / 40) / 3600).toFixed(1));
      this.dashboardStatisticModel.previousDaySeconds = res.previousDaySeconds; //parseFloat((res.previousDaySeconds / 3600).toFixed(1));
      this.dashboardStatisticModel.previousMonthSeconds = res.previousMonthSeconds; //parseFloat((res.previousMonthSeconds / 3600).toFixed(1));
      this.dashboardStatisticModel.previousWeekActivity = parseFloat((((100 * res.previousWeekSeconds) / 40) / 3600).toFixed(1));
      this.dashboardStatisticModel.previousWeekSeconds = res.previousWeekSeconds; //parseFloat((res.previousWeekSeconds / 3600).toFixed(1));

      this.iconForWeekHours = this.dashboardStatisticModel.currentWeekSeconds != this.dashboardStatisticModel.previousWeekSeconds ? this.dashboardStatisticModel.currentWeekSeconds > this.dashboardStatisticModel.previousWeekSeconds ? {'icon' : 'arrow_drop_up', 'color' : 'green'} : {'icon' : 'arrow_drop_down', 'color' : 'red'} : {'icon' : 'arrow_drop_up', 'color' : 'green'};
      this.iconForMonthHours = this.dashboardStatisticModel.currentMonthSeconds != this.dashboardStatisticModel.previousMonthSeconds ? this.dashboardStatisticModel.currentMonthSeconds > this.dashboardStatisticModel.previousMonthSeconds ? {'icon' : 'arrow_drop_up', 'color' : 'green'} : {'icon' : 'arrow_drop_down', 'color' : 'red'} : {'icon' : 'arrow_drop_up', 'color' : 'green'};
      this.iconForDayHours = this.dashboardStatisticModel.currentDaySeconds != this.dashboardStatisticModel.previousDaySeconds ? this.dashboardStatisticModel.currentDaySeconds > this.dashboardStatisticModel.previousDaySeconds ? {'icon' : 'arrow_drop_up', 'color' : 'green'} : {'icon' : 'arrow_drop_down', 'color' : 'red'} : {'icon' : 'arrow_drop_up', 'color' : 'green'};
      this.iconForCurrentWeekActivityHours = this.dashboardStatisticModel.currentWeekActivity != this.dashboardStatisticModel.previousWeekActivity ? this.dashboardStatisticModel.currentWeekActivity > this.dashboardStatisticModel.previousWeekActivity ? {'icon' : 'arrow_drop_up', 'color' : 'green'} : {'icon' : 'arrow_drop_down', 'color' : 'red'} : {'icon' : 'arrow_drop_up', 'color' : 'green'};

      this.weekHoursDiff = this.timeConvert(Math.abs(res.currentWeekSeconds - res.previousWeekSeconds));
      this.weekHoursDiff = this.weekHoursDiff == '' ? '00:00:00' : this.weekHoursDiff;
      this.monthHoursDiff = this.timeConvert(Math.abs(res.currentMonthSeconds - res.previousMonthSeconds));
      this.monthHoursDiff = this.monthHoursDiff == '' ? '00:00:00' : this.monthHoursDiff;
      this.dayHoursDiff = this.timeConvert(Math.abs(this.dashboardStatisticModel.currentDaySeconds - this.dashboardStatisticModel.previousDaySeconds));
      this.dayHoursDiff = this.dayHoursDiff == '' ? '00:00:00' : this.dayHoursDiff;
      this.currentWeekActivityDiff = Math.abs(this.dashboardStatisticModel.currentWeekActivity - this.dashboardStatisticModel.previousWeekActivity).toFixed(1);
      this.dailyStatistics = res.dailyStats;
      this.loadChart(this.dailyStatistics);
    });
  }

  timeConvert(val) {
    let h = Math.floor(val / 3600);
    val %= 3600;
    let m = Math.floor(val / 60);
    let s = (val % 60);

    var min = String(m).padStart(2, "0");
    var hr = String(h).padStart(2, "0");
    var sec = String(s).padStart(2, "0");
    return hr + ":" + min + ":" + sec;
  }

  loadChart(params: any) {
    var data = [];
    var days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

    for (var i in params) {
      data.push({ 'name': days[new Date(params[i].date).getDay()], 'value': (params[i].seconds / 3600).toFixed(1) });
    }
    this.barVerticalOptions1.single = data;
  }

  fetchWeeklyProjectStatistics() {

    var current = new Date();     // get current date    
    var weekstart = current.getDate() - current.getDay() + 1;
    var weekend = weekstart + 6;       // end day is the first day + 6 
    var monday = dateTimeFormatters.formatDate(new Date(current.setDate(weekstart)));
    var sunday = dateTimeFormatters.formatDate(new Date(current.setDate(weekend)));

    this.dashboardStatisticService.fetchWeeklyProjectStats(monday, sunday).subscribe((res: ProjectHourlyStatsModel[]) => {
      this.weeklyProjectStats = res;
      for (var i in res) {
        var key = res[i].projectName + "#" + res[i].taskName;
        if (!this.map.has(key)) {
          this.map[key] = { 'project': res[i].projectName ? res[i].projectName : 'NA', 'task': res[i].taskName ? res[i].taskName : 'NA', 'hours': 0 };
        }
        if (!this.projectMap.has(res[i].projectName)) {
          this.projectMap[res[i].projectName] = { 'project': res[i].projectName ? res[i].projectName : 'NA', 'hours': 0 };
        }
        var temp = this.map[key];
        temp.hours += res[i].seconds;
        this.map.set(key, temp);

        var projectDetails = this.projectMap[res[i].projectName];
        projectDetails.hours += res[i].seconds;
        this.totalHours += res[i].seconds;
        this.projectMap.set(res[i].projectName, projectDetails);

      }
      for (const [key, value] of Object.entries(this.projectMap)) {
        var progress = parseFloat(((100 * value.hours) / this.totalHours).toFixed(1));
        value.hours = this.timeConvert(value.hours);
        value.progress = progress;
      }

      for (const [key, value] of Object.entries(this.map)) {
        var progress = (100 * value.hours) / this.totalHours;
        value.hours = this.timeConvert(value.hours);
        value.progress = progress;
      }
    });
  }

  fetchDailyProjectStatistics() {
    this.dashboardStatisticService.fetchDailyProjectStatistics().subscribe((res: DailyProjectStatisticsModel[]) => {
      this.DailyProjectStatistics = res;
      this.dataSource = new MatTableDataSource<DailyProjectStatisticsModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
