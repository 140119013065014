import { NativeDateAdapter } from '@angular/material/core';


export class AppDateAdapter extends NativeDateAdapter {

  months = this.getMonthNames('short');
  days = this.getDayOfWeekNames('short');
  month = this.getMonthNames('long');

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      let month: string = (date.getMonth()).toString();
      let weekDay = this.days[date.getDay()];
      month = this.months[month];
      let year = date.getFullYear();
      return `${weekDay}, ${month} ${day}, ${year}`;
    } else if (displayFormat === 'range') {
      var tempDate = new Date(date);
      var weekstart = tempDate.getDate();
      var weekend = weekstart - 6;

      var startDate = new Date(tempDate.setDate(weekstart));
      var endDate = new Date(tempDate.setDate(weekend));

      let startMonthDay = endDate.getDate();
      let startWeekDay = this.days[endDate.getDay()];
      let startMonthName = this.months[endDate.getMonth()];
      let startYear = endDate.getFullYear();

      let endMonthDay = startDate.getDate();
      let endWeekDay = this.days[startDate.getDay()];
      let endMonthName = this.months[startDate.getMonth()];
      let endYear = startDate.getFullYear();

      return `${startWeekDay}, ${startMonthName} ${startMonthDay}, ${startYear} - ${endWeekDay}, ${endMonthName} ${endMonthDay}, ${endYear}`;
    } else if (displayFormat === 'current_week') {
      var dates = this.populateDateRange(date);

      var startDate = new Date(dates[dates.length - 1]);
      var endDate = new Date(dates[0]);

      let startMonthDay = endDate.getDate();
      let startWeekDay = this.days[endDate.getDay()];
      let startMonthName = this.months[endDate.getMonth()];
      let startYear = endDate.getFullYear();

      let endMonthDay = startDate.getDate();
      let endWeekDay = this.days[startDate.getDay()];
      let endMonthName = this.months[startDate.getMonth()];
      let endYear = startDate.getFullYear();

      return `${startWeekDay}, ${startMonthName} ${startMonthDay}, ${startYear} - ${endWeekDay}, ${endMonthName} ${endMonthDay}, ${endYear}`;
    } else if (displayFormat === 'current_month') {
      
      var monthName = this.month[date.getMonth()];
      var year = date.getFullYear().toString();
      
      return ` ${monthName} , ${year}`;
    }
    return date.toDateString();
  }

  populateDateRange(d) {
    var currentWeekDay = d.getDay();
    let dateHeaders = [];
    var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay;
    var wkStart = new Date(new Date(d).setDate(d.getDate() - lessDays));
    for (let i = 1; i <= 7; i++) {
      var dateStr = wkStart;
      dateHeaders.push(dateStr);
      wkStart = new Date(wkStart.setDate(wkStart.getDate() + 1));
    }
    return dateHeaders;
  }
}