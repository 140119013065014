import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from 'src/app/core/services/project.service';
import { ActivityTimelineBuilder, ActivityTimelineModel } from '../models/activity-timeline-model';
import { TaskService } from 'src/app/core/services/task.service';
import { ManualActivityService } from 'src/app/core/services/manual-activity.service';
import { MessageService } from '../factory/message.service';
import { DatePipe,Location } from '@angular/common';
import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { Router, ActivatedRoute } from '@angular/router';

// Add activity timeline modal
export const MY_NATIVE_FORMATS = {
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',second:'numeric'}
};

    @Component({
    selector: 'app-add-activity-timeline',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>timeline</mat-icon><span>&nbsp;&nbsp;Add Timeline Activity</span></h2>
    <hr/>
    <div [formGroup]="activityTimelineForm">
    <mat-dialog-content>
        <p>
            <mat-form-field class="w-100" appearance="outline">
            <mat-label>Project</mat-label>
            <mat-select formControlName="projectId" (ngModelChange)="changeProject($event)" required>
            <mat-option *ngFor="let project of projects; let i = index;" [value]="project.id">{{ project.name }}</mat-option>
            </mat-select>
            <mat-hint>Select project</mat-hint>
            <mat-error *ngIf="!activityTimelineForm.controls.projectId.valid">Project is required!</mat-error>
        </mat-form-field>
        </p>
        <p>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select task</mat-label>
            <mat-select formControlName="taskId" required>
                <mat-option *ngFor="let task of tasks; let i = index;" [value]="task.id">{{task.name}}</mat-option>
            </mat-select>
            <mat-hint>Select task</mat-hint>
            <mat-error *ngIf="!activityTimelineForm.controls.taskId.valid">Task is required!</mat-error>
            </mat-form-field>
        </p>
        <p *ngIf="loading" style='position: fixed; z-index: 1000;background: rgba(0, 0, 0, 0.2); bottom: 0; top: 0; left: 0; right: 0;'>
          <mat-spinner class="spinner" [color]="color" [mode]="mode" [diameter]="25" style='position: absolute; fixed; top: 35%; left: 50%;'></mat-spinner>
        </p>
        <p>
            <mat-form-field class="w-100" appearance="outline">
            <mat-label>Start activity date time</mat-label>
            <input matInput placeholder="Enter time" [owlDateTime]="activityStartTime" readonly disabled formControlName="activityStartTime" required>
            <a style="position: absolute; top: 0; right: 0; color: #000;" mat-icon-button>
            <mat-icon matSuffix [owlDateTimeTrigger]="activityStartTime">access_time</mat-icon>
            </a>
            <owl-date-time [pickerMode]="'dialog'" [showSecondsTimer]="true"  #activityStartTime></owl-date-time>
            <mat-hint>Select start activity datetime</mat-hint>
            <mat-error *ngIf="!activityTimelineForm.controls.activityStartTime.valid">Activity start datetime is required!</mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="w-100" appearance="outline">
            <mat-label>Stop activity date time</mat-label>
            <input matInput placeholder="Enter time" [owlDateTime]="activityEndTime" readonly disabled formControlName="activityEndTime" required>
            <a style="position: absolute; top: 0; right: 0; color: #000;" mat-icon-button>
            <mat-icon matSuffix [owlDateTimeTrigger]="activityEndTime">access_time</mat-icon>
            </a>
            <owl-date-time [pickerMode]="'dialog'" [showSecondsTimer]="true"  #activityEndTime></owl-date-time>
            <mat-hint>Select stop activity datetime</mat-hint>
            <mat-error *ngIf="!activityTimelineForm.controls.activityEndTime.valid">Activity end datetime is required!</mat-error>
            </mat-form-field>
    </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button (click)="submitManualActivity()" mat-raised-button color="primary" class="w-100" [disabled]="activityTimelineForm.invalid">Submit</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div></div>`,
    providers: [
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS}
      ]
})

export class AddTimelineActivity implements OnInit {

    selectedFileName = "";
    agreementLetter: any = null;
    projects = [];
    tasks = [];
    color = 'primary';
    mode = 'determinate';
    loading:boolean = false;

    /**
     *  Activity timeline formGroup instance
     */
    activityTimeline: ActivityTimelineBuilder = new ActivityTimelineBuilder();
    activityTimelineModel: ActivityTimelineModel = this.activityTimeline.activityTimelineModel;
    activityTimelineForm: FormGroup = this.activityTimeline.activityTimelineForm;


    constructor(
        private projectService:ProjectService,
        private taskService: TaskService,
        private dialog: MatDialog,
        private manualActivityService: ManualActivityService,
        private messageService: MessageService,
        public datepipe: DatePipe,
        public router: Router,
        public location:Location
    ) { }

    /**
     * Fetch the projects which are accessible by user
     */
    fetchProjects(){
        this.projectService.fetchProjects().subscribe((res) => {
          this.projects = res;
        });
    }
    
    /**
     * Initial method
     */
    ngOnInit() { 
        this.fetchProjects();
    }

    /**
     * This method is responsible for getting task list based on project change
     * @param $event 
     */
    changeProject($event){
        var projectId = $event;
        this.mode = 'Indeterminate';
        this.loading = true;
        this.taskService.findTasksByProjectId(projectId).subscribe((res) => {
            this.tasks = res;
            this.mode = 'determinate';
            this.loading = false;
        });
    }

    /**
     * This method is responsible for validating activity timeline and create the activity
     */
     submitManualActivity() {
        var activityEndDate = this.datepipe.transform(this.activityTimelineModel.activityEndTime, 'yyyy-MM-dd HH:mm:ss');
        var activityStartDate = this.datepipe.transform(this.activityTimelineModel.activityStartTime, 'yyyy-MM-dd HH:mm:ss');
        this.activityTimelineModel.activityEndTime = activityEndDate;
        this.activityTimelineModel.activityStartTime = activityStartDate;
        if (activityEndDate < activityStartDate) {
            this.messageService.display('Activity end datetime must be greater then start datetime !', 'OK', 'error');
        }
        else{
        this.manualActivityService.checkTimelineExist(activityStartDate, activityEndDate).subscribe((res) => {
            if (res) {
                this.messageService.display('Activity already exist !', 'OK', 'error');
            } else {
                this.addActivity(this.activityTimelineModel);
            }
        });
        }
    }

    /**
     * This method is responsible for creating activity timeline manually.
     */
    addActivity(activityTimelineModel:ActivityTimelineModel){
        this.manualActivityService.addTimelineManually(activityTimelineModel).subscribe((res) => {
            this.messageService.display('Activity added successfully ! Actvity id :' + res, 'OK', 'success');
            this.dialog.closeAll();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/activitySampleScreenshot']);
            }); 
        });
    } 

}
