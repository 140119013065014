import { Component, OnInit } from '@angular/core';
import { DashboardStatisticService } from '../../core/services/dashboard-statistic.service';
import { ProjectWorkHoursModel, ProjectWorkHoursBuilder } from '../../shared/models/project-work-hours-model';
import { Location } from '@angular/common';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { ConstantPool } from '@angular/compiler';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { EmployeeService } from '../../core/services/employee.service';
import { EmployeeModel, EmployeeModelBuilder } from 'src/app/shared/models/employee-model';
import { MatTable, MatTableDataSource  } from '@angular/material/table';
import { MatDateFormats, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { AppDateAdapter } from '../helper/format-datepicker';

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'current_week',
    monthYearLabel: 'MMMM d, y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-weekly-timesheet',
  templateUrl: './weekly-timesheet.component.html',
  styleUrls: ['./weekly-timesheet.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class WeeklyTimesheetComponent implements OnInit {

  weeklyTimesheet: Map<string, any> = new Map();
  dateHeaders: Map<string, any> = new Map();
  projectLabel = '';
  totalHoursLabel = '';
  date1 = new FormControl(new Date());

  employees: EmployeeModel[] = [];
  selectedDate = '';
  selectedEmpBuilder: EmployeeModelBuilder = new EmployeeModelBuilder();
  selectedEmpModel: EmployeeModel = this.selectedEmpBuilder.employeeModel;

  selectedEmp = new FormControl(new EmployeeModelBuilder().employeeModel.id);
  overAllDuration = null;
  showNoContentLabel = false;
  screenshot = {
    img: './assets/images/shutterstock.jpg'
  };
  prevBtnImg = {
    img: './assets/images/icons8-prev-50.png'
  }
  nextBtnImg = {
    img: './assets/images/icons8-right-button-50.png'
  }
  
  constructor(
    public location: Location,
    private dashboardStatisticService: DashboardStatisticService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.fetchWeeklyTimesheet();
    this.fetchEmployees();
  }

  fetchEmployees() {
    if(sessionStorage.getItem('employees')) {
      this.employees = JSON.parse(sessionStorage.getItem('employees'));
    } else {
      this.employeeService.fetchLiteEmployees().subscribe(res => {
        this.employees = res;
      });
    }
  }

  fetchWeeklyTimesheet() {
    var date = dateTimeFormatters.formatDate(this.date1.value);
    this.dateHeaders.clear();
    this.weeklyTimesheet = new Map();
    if (this.selectedEmp.value != -1) {
      this.dashboardStatisticService.fetchWeeklyTimesheetForEmp(date, this.selectedEmp.value).subscribe((res: ProjectWorkHoursModel[]) => {
        this.populateTable(res);
      });
    } else {
      this.dashboardStatisticService.fetchWeeklyTimesheet(date).subscribe((res: ProjectWorkHoursModel[]) => {
        this.populateTable(res);
      });
    }
  }

  populateDateRange(d) {
    var currentWeekDay = d.getDay();
    var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
    var wkStart = new Date(new Date(d).setDate(d.getDate() - lessDays));
    this.dateHeaders.clear();
    for (let i = 1; i <= 7; i++) {
      var dateStr = dateTimeFormatters.formatDate(wkStart);
      this.dateHeaders.set(dateStr, 0);
      wkStart = new Date(wkStart.setDate(wkStart.getDate() + 1));
    }
  }

  timeConvert(val) {
    let h = Math.floor(val / 3600);
    val %= 3600;
    let m = Math.floor(val / 60);
    let s = (val % 60);

    var min = String(m).padStart(2, "0");
    var hr = String(h).padStart(2, "0");
    var sec = String(s).padStart(2, "0");
    
    var str = hr + ":" + min + ":" + sec;
    if(str == '00:00:00') {
      str = '-';
    }
    return str;
  }

  populateTable(res) {
    if (res.length) {
      this.populateDateRange(this.date1.value);
    }
    this.projectLabel = '';
    this.totalHoursLabel = '';

    for (var i in res) {
      var key = res[i].project + "#" + res[i].client + "#" + res[i].allTasks;
      if (!this.weeklyTimesheet.has(key)) {
        this.weeklyTimesheet[key] = { 'project': res[i].project, 'client': res[i].client, 'task': res[i].allTasks, 'hours': new Map<any, any>() };
      }
      var temp = this.weeklyTimesheet[key];
      var hours = temp.hours;
      if (!hours.has(res[i].date)) {
        hours.set(res[i].date, 0);
      }
      var tempHours = hours.get(res[i].date);
      hours.set(res[i].date, tempHours + res[i].seconds);
      temp.hours = hours;
      this.weeklyTimesheet.set(key, temp);
    }
    if (this.weeklyTimesheet.size) {
      this.projectLabel = 'Project';
      this.totalHoursLabel = 'Total Hours';
    } else {
      this.projectLabel = 'No Project / Task to show during this time';
      this.totalHoursLabel = '';
    }

    for (let [k, v] of Object.entries(this.weeklyTimesheet)) {
      var dates = this.dateHeaders;
      var hours = v.hours;
      this.dateHeaders.forEach((value, key) => {
        if (hours.get(key) == undefined) {
          hours.set(key, 0);
        }
        var hour = hours.get(key);
        value += hour;
        if(v['total'] == undefined) {
          v['total'] = 0;
        }
        var temp = v['total'];
        v['total'] =  temp + hour;

        hour = this.timeConvert(hour);
        hours.set(key, hour);
        this.dateHeaders.set(key, value);
        
      });
      v.hours = hours;
    }
    this.dateHeaders.forEach((value, key) => {
      value = this.timeConvert(value);
      this.dateHeaders.set(key, value);
    });
    this.overAllDuration = 0;
    for(let [k, v] of Object.entries(this.weeklyTimesheet)) {
      this.overAllDuration += v.total;
      v.total = this.timeConvert(v.total);
    }
    this.overAllDuration = this.timeConvert(this.overAllDuration);
    if(!this.weeklyTimesheet.size) {
      this.showNoContentLabel = true;
    } else {
      this.showNoContentLabel = false;
    }
  }

  previousDay() {
    var currDate = this.date1.value;
    this.date1 = new FormControl(new Date(currDate.setDate(currDate.getDate() - 7)));
    this.fetchWeeklyTimesheet();
  }

  nextDay() {
    var currDate = this.date1.value;
    this.date1 = new FormControl(new Date(currDate.setDate(currDate.getDate() + 7)));
    this.fetchWeeklyTimesheet();
  }
}