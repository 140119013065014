import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { CompensatoryRequestBuilder, CompensatoryRequestModel } from "../models/ewh-request-model";
import { FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { CompensatoryRequestActionBuilder, CompensatoryRequestActionModel } from "../models/ewh-request-action-model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CompensatoryRequestService } from "src/app/core/services/ewh-request.service";
import { CompensatoryRequestApproverService } from "src/app/core/services/ewh-request-approver.service";
import { Router } from "@angular/router";
import { CommonService } from "../factory/common.service";
import { MessageService } from "../factory/message.service";
import { LoaderService } from "../factory/loader.service";
import { UserService } from "src/app/core/services/user.service";
import { UserModel } from "../models/user-model";


//Ewh  Action Dialog
@Component({
   selector: 'app-ewh-action-dialog',
   template: `
      <div [formGroup]="compensatoryActionForm">
      	<h1 mat-dialog-title align="center">Compensatory Request action</h1>
      	<div mat-dialog-content>
      		<hr/>
      		<div class="col-sm" formGroupName="employeeDTOLite">
      			<div class="row">
      				<div class="col-sm">
      					<mat-label>Employee Name</mat-label>
      				</div>
      				<div class="col-sm">
      					<input matInput readonly formControlName="fullName">
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>Compensatory Date</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.date.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>Entry Time</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.entryTime.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>Exit Time</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.exitTime.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>ProjectName</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.projectName.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>Tasks</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.tasks.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>Compensation</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.compensation.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm">
      				<div class="row">
      					<div class="col-sm">
      						<mat-label>Total Hours</mat-label>
      					</div>
      					<div class="col-sm">
      						<mat-label>{{compensatoryActionForm.controls.totalHours.value}}</mat-label>
      					</div>
      				</div>
      			</div>
      			<hr/>
      			<div class="col-sm mt-3" *ngIf="compensatoryRequestModel.managerCompensatoryRequestStatus === 'PENDING_APPROVAL' && compensatoryRequestModel.hrCompensatoryRequestStatus === 'PENDING_APPROVAL'">
      				<p>
      					<mat-form-field class="w-100" appearance="outline">
      						<mat-label>Select Users</mat-label>
      						<mat-select formControlName="encashmentCompensatoryRequestApprovers" multiple required [(value)]="selectedApprovers">
      							<mat-option *ngFor="let user of approvers;" [value]="user.employeeLite.id">{{user.employeeLite.firstName}} {{user.employeeLite.lastName}}</mat-option>
      						</mat-select>
      						<mat-hint>Select users to whom you want to share email notification in case of Compensatory request approved</mat-hint>
      					</mat-form-field>
      				</p>
      				<mat-error *ngIf="compensatoryActionForm.get('encashmentCompensatoryRequestApprovers').hasError('required')">Please select at least one user.</mat-error>
      			</div>
      			<div class="col-sm" *ngIf="compensatoryRequestModel.managerCompensatoryRequestStatus === 'PENDING_APPROVAL' && compensatoryRequestModel.hrCompensatoryRequestStatus === 'PENDING_APPROVAL'">
      				<p>
      					<mat-form-field class="w-100" appearance="outline">
      						<mat-label>Approver Comment</mat-label>
      						<input formControlName="approverActionComment" maxlength="40" minlength="3" matInput placeholder="Enter Approver Comment" required>
      							<mat-hint>Enter comment</mat-hint>
      							<mat-error *ngIf="compensatoryActionForm.controls.approverActionComment.invalid">Approver comment is required!</mat-error>
      						</mat-form-field>
      					</p>
      				</div>
      				<div class="col-sm" *ngIf="compensatoryRequestModel.managerCompensatoryRequestStatus === 'APPROVED'">
      					<p>
      						<mat-form-field class="w-100" appearance="outline">
      							<mat-label>Salary</mat-label>
      							<input formControlName="salary" type="number" matInput placeholder="Enter Salary" step="any" min="0" (input)="onSalaryInput($event)" required>
      								<mat-hint>Please enter a numeric value</mat-hint>
      								<mat-error *ngIf="compensatoryActionForm.controls.salary.invalid">Salary required!</mat-error>
      							</mat-form-field>
      						</p>
      					</div>
      					<div class="col-sm" *ngIf="compensatoryRequestModel.managerCompensatoryRequestStatus === 'APPROVED'">
      						<p>
      							<mat-form-field class="w-100" appearance="outline">
      								<mat-label>Hr Comment</mat-label>
      								<input formControlName="hrActionComment" maxlength="40" minlength="3" matInput placeholder="Enter Hr Comment" required>
      									<mat-hint>Enter comment</mat-hint>
      									<mat-error *ngIf="compensatoryActionForm.controls.hrActionComment.invalid">Hr comment is required!</mat-error>
      								</mat-form-field>
      							</p>
      						</div>
      						<div class="text-right" *ngIf="compensatoryRequestModel.managerCompensatoryRequestStatus === 'PENDING_APPROVAL' && compensatoryRequestModel.hrCompensatoryRequestStatus === 'PENDING_APPROVAL'">
      							<button type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="ApproveAction()" [disabled]="!isApproveButtonEnabled()">Approve</button>
      							<button type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="RejectAction()" [disabled]="compensatoryActionForm.controls.approverActionComment.invalid">Reject</button>
      							<button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
      						</div>
      						<div class="text-right" *ngIf="compensatoryRequestModel.managerCompensatoryRequestStatus === 'APPROVED'">
      							<button type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="AdjustComp()" [disabled]="isAdjustButtonDisabled()">Adjust</button>
      							<button type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="DeclineComp()" [disabled]="compensatoryActionForm.controls.hrActionComment.invalid">Decline</button>
      							<button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
      						</div>
      					</div>
      				</div>`
})

export class CompensatoryActionRequestComponent implements OnInit {

   compensatoryRequest: CompensatoryRequestBuilder = new CompensatoryRequestBuilder();
   compensatoryRequestModel: CompensatoryRequestModel = this.compensatoryRequest.compensatoryRequestModel;
   compensatoryActionForm: FormGroup = this.compensatoryRequest.compensatoryRequestForm;

   dataSource: MatTableDataSource<CompensatoryRequestModel>;

   compensatoryRequestAction: CompensatoryRequestActionBuilder = new CompensatoryRequestActionBuilder();
   compensatoryRequestActionModel: CompensatoryRequestActionModel = this.compensatoryRequestAction.compensatoryRequestActionModel;
   compensatoryRequestActionForm: FormGroup = this.compensatoryRequestAction.compensatoryRequestActionForm;

   compensatoryRequestForm: any;
   dateTimeFormatters: any;
   loggedUserId: number;
   compensatoryRequestedDate: string;
   approvers = [];
   selectedApprovers: any[] = [];
   isManagerApprover: boolean = false;
   isHrApprover: boolean = false;
  
   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private compensatoryRequestService: CompensatoryRequestService,
      private compensatoryRequestApproverService: CompensatoryRequestApproverService,
      public router: Router,
      public commonServices: CommonService,
      public messageService: MessageService,
      public dialogRef: MatDialogRef<CompensatoryActionRequestComponent>,
      private loaderService: LoaderService,
      private userService: UserService
   ) { }

   ngOnInit() {
      this.populateCompensatoryRequestDto();
      
   }

   populateCompensatoryRequestDto() {
      this.compensatoryRequestService.fetchCompensatoryByIdWithDetail(this.data.encashmentCompensatoryRequestId).subscribe((res) => {
         this.fetchAllUsers();
         this.compensatoryRequest.setModelVals(res);
      });
   }
   onSalaryInput(event: any): void {
      const input = event.target as HTMLInputElement;
      const value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
         input.value = ''; // Set the value to 0 if it's NaN or negative
      }
   }

   isSalaryNumeric(): boolean {
      const salary = this.compensatoryActionForm.controls.salary.value;
      return !isNaN(salary) && !isNaN(parseFloat(salary));
   }

   isAdjustButtonDisabled(): boolean {
      return this.compensatoryActionForm.controls.salary.invalid ||
         this.compensatoryActionForm.controls.salary.value === '' ||
         this.compensatoryActionForm.controls.hrActionComment.invalid;
   }

   isApproveButtonEnabled(): boolean {
      return this.selectedApprovers.length > 0 && !this.compensatoryActionForm.controls.approverActionComment.invalid;
   }

   fetchAllUsers() {

      this.userService.fetchCompensatoryRequestApproverHr().subscribe((res: UserModel[]) => {

         this.approvers = res;
      });
   }

   ApproveAction() {
      var retVal = confirm("Do you want to Approve Compensatory request?");
      if (retVal == true) {
         this.compensatoryRequestActionModel.approverActionComment = this.compensatoryRequestModel.approverActionComment;
         this.compensatoryRequestActionModel.encashmentCompensatoryRequestId = this.data.encashmentCompensatoryRequestId;
         this.compensatoryRequestActionModel.managerCompensatoryRequestStatus = this.compensatoryRequestModel.managerCompensatoryRequestStatus;
         this.compensatoryRequestActionModel.hrCompensatoryRequestStatus = this.compensatoryRequestModel.hrCompensatoryRequestStatus;
         this.compensatoryRequestActionModel.listOfUserForNotificationReference = this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers;
         this.compensatoryRequestActionModel.compensatoryApprover = this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers[0];
         this.compensatoryRequestActionModel.encashmentCompensatoryType = this.compensatoryRequestModel.encashmentCompensatoryType;
         this.compensatoryRequestActionModel.entryTime = this.compensatoryRequestModel.entryTime;
         this.compensatoryRequestActionModel.exitTime = this.compensatoryRequestModel.exitTime;
         this.compensatoryRequestActionModel.tasks = this.compensatoryRequestModel.tasks;
         this.compensatoryRequestActionModel.projectName = this.compensatoryRequestModel.projectName;
         this.compensatoryRequestActionModel.date = this.compensatoryRequestModel.date;
         this.compensatoryRequestActionModel.compensation = this.compensatoryRequestModel.compensation;


         this.messageService.display("Loding...", 'ok', 'success');

         this.compensatoryRequestApproverService.approveCompensatory(this.compensatoryRequestActionModel).subscribe((res) => {
            this.messageService.display('Compensatory request approve successfully!', 'OK', 'success');
            this.dialogRef.close('applied');
            this.loaderService.updateInboxAction(true);
         });
      }

      else {
         return false;
      }
   }

   RejectAction() {
      var retVal = confirm("Do you want to Reject Compensatory request?");
      if (retVal == true) {
         this.compensatoryRequestActionModel.approverActionComment = this.compensatoryRequestModel.approverActionComment;
         this.compensatoryRequestActionModel.encashmentCompensatoryRequestId = this.data.encashmentCompensatoryRequestId;
         this.compensatoryRequestActionModel.managerCompensatoryRequestStatus = this.compensatoryRequestModel.managerCompensatoryRequestStatus;
         this.compensatoryRequestActionModel.hrCompensatoryRequestStatus = this.compensatoryRequestModel.hrCompensatoryRequestStatus;
         this.compensatoryRequestActionModel.listOfUserForNotificationReference = this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers;
         this.compensatoryRequestActionModel.compensatoryApprover = this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers[0];
         this.compensatoryRequestActionModel.encashmentCompensatoryType = this.compensatoryRequestModel.encashmentCompensatoryType;
         this.compensatoryRequestActionModel.entryTime = this.compensatoryRequestModel.entryTime;
         this.compensatoryRequestActionModel.exitTime = this.compensatoryRequestModel.exitTime;
         this.compensatoryRequestActionModel.tasks = this.compensatoryRequestModel.tasks;
         this.compensatoryRequestActionModel.projectName = this.compensatoryRequestModel.projectName;
         this.compensatoryRequestActionModel.date = this.compensatoryRequestModel.date;

         this.compensatoryRequestApproverService.rejectCompensatory(this.compensatoryRequestActionModel).subscribe((res) => {
            this.messageService.display('Compensatory request reject successfully!  ', 'OK', 'success');
            this.dialogRef.close('applied');
            this.loaderService.updateInboxAction(true);
         });

      }
      else {
         return false;
      }
   }

   AdjustComp() {
      var retVal = confirm("Do you want to Adjust Compensatory request?");
      if (retVal == true) {

         var obj = {
            "hrActionComment": this.compensatoryRequestModel.hrActionComment,
            "salary": this.compensatoryRequestModel.salary,
            "encashmentCompensatoryRequestId": this.data.encashmentCompensatoryRequestId
         };

         this.messageService.display("Loding...", 'ok', 'success');

         this.compensatoryRequestApproverService.adjustCompensatory(JSON.stringify(obj)).subscribe((res) => {
            this.messageService.display('Compensatory request Adjust successfully!', 'OK', 'success');
            this.dialogRef.close('applied');
            this.loaderService.updateInboxAction(true);
         });
      }

      else {
         return false;
      }
   }

   DeclineComp() {
      var retVal = confirm("Do you want to Decline Compensatory request?");
      if (retVal == true) {
         this.compensatoryRequestActionModel.approverActionComment = this.compensatoryRequestModel.approverActionComment;
         this.compensatoryRequestActionModel.hrActionComment = this.compensatoryRequestModel.hrActionComment;
         this.compensatoryRequestActionModel.encashmentCompensatoryRequestId = this.data.encashmentCompensatoryRequestId;
         this.compensatoryRequestActionModel.managerCompensatoryRequestStatus = this.compensatoryRequestModel.managerCompensatoryRequestStatus;
         this.compensatoryRequestActionModel.hrCompensatoryRequestStatus = this.compensatoryRequestModel.hrCompensatoryRequestStatus;
         this.compensatoryRequestActionModel.listOfUserForNotificationReference = this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers;
         this.compensatoryRequestActionModel.compensatoryApprover = this.compensatoryRequestModel.encashmentCompensatoryRequestApprovers;
         this.compensatoryRequestActionModel.encashmentCompensatoryType = this.compensatoryRequestModel.encashmentCompensatoryType;
         this.compensatoryRequestActionModel.entryTime = this.compensatoryRequestModel.entryTime;
         this.compensatoryRequestActionModel.exitTime = this.compensatoryRequestModel.exitTime;
         this.compensatoryRequestActionModel.tasks = this.compensatoryRequestModel.tasks;
         this.compensatoryRequestActionModel.projectName = this.compensatoryRequestModel.projectName;
         this.compensatoryRequestActionModel.date = this.compensatoryRequestModel.date;
         this.compensatoryRequestActionModel.compensation = this.compensatoryRequestModel.compensation;

         this.compensatoryRequestApproverService.declineCompensatory(this.compensatoryRequestActionModel).subscribe((res) => {
            this.messageService.display('Compensatory request Decline successfully!', 'OK', 'success');
            this.dialogRef.close('applied');
            this.loaderService.updateInboxAction(true);
         });

      }
      else {
         return false;
      }
   }
}
