
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface TimesheetTaskDetailsModel {
    activityStartTime : any,
    activityEndTime : any,
    date : any,
    projectTaskDetails : Map<string, string[]>

}

export class TimesheetTaskDetailsBuilder {
    timesheetTaskDetailsModel: TimesheetTaskDetailsModel = {
        activityStartTime : '',
        activityEndTime : '',
        date : '',
        projectTaskDetails : new Map<'', []>()
    };

    timesheetTaskDetailsModelForm: FormGroup = new FormGroup({
        //activityTimeLineId: new FormControl(this.activitySampleModel.activityTimeLineId),
        activityStartTime: new FormControl(this.timesheetTaskDetailsModel.activityStartTime),
        activityEndTime: new FormControl(this.timesheetTaskDetailsModel.activityEndTime),
        date: new FormControl(this.timesheetTaskDetailsModel.date),
        projectTaskDetails : new FormControl(this.timesheetTaskDetailsModel.projectTaskDetails)
    });
    constructor() {
        this.timesheetTaskDetailsModelForm.valueChanges.subscribe(val => {
            this.timesheetTaskDetailsModel.activityStartTime = val.activityStartTime;
            this.timesheetTaskDetailsModel.activityEndTime = val.activityEndTime;
            this.timesheetTaskDetailsModel.date = val.date;
            this.timesheetTaskDetailsModel.projectTaskDetails = val.projectTaskDetails;
        });
    }
    setModelVals(res: TimesheetTaskDetailsModel) {
        this.timesheetTaskDetailsModelForm.controls.activityStartTime.setValue(res.activityStartTime);
        this.timesheetTaskDetailsModelForm.controls.activityEndTime.setValue(res.activityEndTime);
        this.timesheetTaskDetailsModelForm.controls.date.setValue(res.date);
        this.timesheetTaskDetailsModelForm.controls.projectTaskDetails.setValue(res.projectTaskDetails);
    }
}