
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface DailyProjectStatisticsModel {
    projectName: string,
    date: string,
    startTime: any,
    endTime: any
}

export class DailyProjectStatisticsBuilder {
    dailyProjectStatisticsModel: DailyProjectStatisticsModel = {
        projectName: '',
        date: '',
        startTime: '',
        endTime: ''
    };

    dailyProjectStatisticsForm: FormGroup = new FormGroup({
        projectName: new FormControl(this.dailyProjectStatisticsModel.projectName),
        date: new FormControl(this.dailyProjectStatisticsModel.date),
        startTime: new FormControl(this.dailyProjectStatisticsModel.startTime),
        endTime: new FormControl(this.dailyProjectStatisticsModel.endTime)

    });
    constructor() {
        this.dailyProjectStatisticsForm.valueChanges.subscribe(val => {
            this.dailyProjectStatisticsModel.projectName = val.projectName;
            this.dailyProjectStatisticsModel.date = val.date;
            this.dailyProjectStatisticsModel.startTime = val.startTime;
            this.dailyProjectStatisticsModel.endTime = val.endTime;


        });
    }
    setModelVals(res: DailyProjectStatisticsModel) {
        this.dailyProjectStatisticsForm.controls.projectName.setValue(res.projectName);
        this.dailyProjectStatisticsForm.controls.date.setValue(res.date);
        this.dailyProjectStatisticsForm.controls.startTime.setValue(res.startTime);
        this.dailyProjectStatisticsForm.controls.endTime.setValue(res.endTime);
    }
}