import { FormGroup, FormControl } from '@angular/forms'

export interface EmployeeModel {
    id : number,
    firstName : string,
    lastName : string,
}

export class EmployeeModelBuilder {
    employeeModel : EmployeeModel = {
        id : -1,
        firstName : '',
        lastName : ''
    }

    employeeFormGroup : FormGroup = new FormGroup({
        id : new FormControl(this.employeeModel.id),
        firstName : new FormControl(this.employeeModel.firstName),
        lastName : new FormControl(this.employeeModel.lastName),
    });

    constructor() {
        this.employeeFormGroup.valueChanges.subscribe(val => {
            this.employeeModel.id = val.id;
            this.employeeModel.firstName = val.firstName; 
            this.employeeModel.lastName = val.lastName; 
        });
    }
    setModelVals(res: EmployeeModel) {
       this.employeeFormGroup.controls.id.setValue(res.id);
       this.employeeFormGroup.controls.firstName.setValue(res.firstName);
       this.employeeFormGroup.controls.lastName.setValue(res.lastName);
    }
}