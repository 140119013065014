import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { IntegrationModel } from 'src/app/shared/models/integration-model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  private stateUrl = 'api/v1/integration';

   constructor(
    private httpRequest: HttpRequestService
  ) { }
  
  fetchIntegrations(projectId:any): Observable<IntegrationModel> {
    return this.httpRequest.get(this.stateUrl + '/'+projectId);
  }
   

}
