import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { GitlabIntegrationModel } from 'src/app/shared/models/gitlab-integration-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGitlabIntegrationDetailService {

  private stateUrl = 'api/v1/gitlab-integration-detail';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addGitlabIntegrationDetail(gitlabIntegrationModel: GitlabIntegrationModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/add-project-integration', gitlabIntegrationModel);
  }

  updateGitlabIntegrationDetail(gitlabIntegrationModel: GitlabIntegrationModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-project-integration', gitlabIntegrationModel);

  }
  checkIntegrationExist(projectId: number): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/check-integration-exist/' + projectId);
  }

  /**
   * common service for get list of integration list corresponding to project
   * @param projectId 
   */
  fetchIntegrationDetails(projectId: number): Observable<GitlabIntegrationModel> {
    return this.httpRequest.get(this.stateUrl + '/fetch-integration-details/' + projectId);
  }
}
