import { Component, OnInit } from '@angular/core';
import { TaskFormBuilder, TaskModel } from 'src/app/shared/models/task-model';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/shared/factory/message.service';
import { TaskService } from 'src/app/core/services/task.service';
import { Location } from '@angular/common';
import { ProjectService } from 'src/app/core/services/project.service';

@Component({
  selector: 'app-task-add-edit',
  templateUrl: './task-add-edit.component.html',
  styleUrls: ['./task-add-edit.component.css']
})
export class TaskAddEditComponent implements OnInit {

  task: TaskFormBuilder = new TaskFormBuilder();
  taskModel: TaskModel = this.task.taskModel;
  taskForm: FormGroup = this.task.taskForm;
  projects = []
  // Task default status
  status = "OPEN";
  

  compareFn(obj1: TaskModel, obj2: TaskModel) {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router : Router,
    private taskService: TaskService,
    private location: Location,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.fetchProjects();
    this.taskModel.id = parseInt(this.route.snapshot.paramMap.get('taskId'), 0);
    this.taskModel.id ? this.fetchTaskById() : null 
  }

  fetchTaskById(){
    this.taskService.fetchTaskById(this.taskModel.id).subscribe((res:TaskModel) => {
        this.task.setModelVals(res);
    }); 
  }

  fetchProjects(){
    this.projectService.fetchProjects().subscribe((res) => {
      this.projects = res;
    });
  }

  addUpdateTask() {
    if (this.taskModel.id) {
      // append default task status
      this.taskModel.status = this.status;
      this.taskService.updateTask(this.taskModel).subscribe((res) => {
        this.messageService.display('Task update is successfull!Task ID : ' + this.taskModel.id, 'OK', 'success');
        this.router.navigate(['/tasks']);
      });
    } else {
      this.taskModel.id = null;
      // append default task status
      this.taskModel.status = this.status;
      this.taskService.addTask(this.taskModel).subscribe((res) => {
        this.messageService.display('Task add is successfull! Task ID : ' + res, 'OK', 'success');
        this.router.navigate(['/tasks']);
      });
    }
  }

}
