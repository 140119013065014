import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { PivotalTrackerIntegrationModel } from 'src/app/shared/models/pivotal-tracker-model';

@Injectable({
  providedIn: 'root'
})
export class UserPivotalTrackerIntegrationDetailService {

  private stateUrl = 'api/v1/pivotal-integration-detail';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addPivotalTrackerIntegrationDetail(pivotalTrackerIntegrationModel: PivotalTrackerIntegrationModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/add-project-integration', pivotalTrackerIntegrationModel);
  }

  updatePivotalTrackerIntegrationDetail(pivotalTrackerIntegrationModel: PivotalTrackerIntegrationModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-project-integration', pivotalTrackerIntegrationModel);
    
  }
  checkIntegrationExist(projectId:number): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/check-integration-exist/'+projectId);
  }

  /**
   * common service for get list of integration list corresponding to project
   * @param projectId 
   */
  fetchIntegrationDetails(projectId:number): Observable<PivotalTrackerIntegrationModel> {
    return this.httpRequest.get(this.stateUrl + '/fetch-integration-details/'+projectId);
  }
}
