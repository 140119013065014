

import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface ActivityTimelineModel {
    activityStartTime: any,
    activityEndTime: any,
    taskId:any,
    projectId:any
}

export class ActivityTimelineBuilder {
   
    activityTimelineModel: ActivityTimelineModel = {
        activityStartTime: '',
        activityEndTime: '',
        taskId: null,
        projectId: ''
   };
    activityTimelineForm: FormGroup = new FormGroup({
        activityStartTime : new FormControl(this.activityTimelineModel.activityStartTime),
        activityEndTime: new FormControl(this.activityTimelineModel.activityEndTime),
        taskId: new FormControl(this.activityTimelineModel.taskId),
        projectId : new FormControl(this.activityTimelineModel.projectId),
    });
    constructor() {
        this.activityTimelineForm.valueChanges.subscribe(val => {
            this.activityTimelineModel.activityStartTime = val.activityStartTime;
            this.activityTimelineModel.activityEndTime = val.activityEndTime;
            this.activityTimelineModel.taskId = val.taskId;
            this.activityTimelineModel.projectId = val.projectId;
        });
    }
    setModelVals(res: ActivityTimelineModel) {
        this.activityTimelineForm.controls.activityStartTime.setValue(res.activityStartTime);
        this.activityTimelineForm.controls.activityEndTime.setValue(res.activityEndTime);
        this.activityTimelineForm.controls.taskId.setValue(res.taskId);
        this.activityTimelineForm.controls.projectId.setValue(res.projectId);
    }
}