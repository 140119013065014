import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { ProjectLiteModel } from 'src/app/shared/models/project-lite-model';
import { ProjectIntegrationModel } from 'src/app/shared/models/project-integration-model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private stateUrl = 'api/v1/read-project';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchProjects(): Observable<ProjectLiteModel[]> {
    return this.httpRequest.get(this.stateUrl + '/project-list');
  }

  /**
   * This Service call to diff state url api/v1/manage-project for getting project information
   * @param projectId 
   */
  fetchProject(projectId:any): Observable<ProjectLiteModel>{
    return this.httpRequest.get('api/v1/manage-project/'+projectId);
  }

}
