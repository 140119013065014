import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DashboardStatisticService } from '../../core/services/dashboard-statistic.service';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpRequestService } from '../../core/http/http-request.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDateFormats, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { ModalComponent } from '../modal/modal.component';
import { EmployeeService } from '../../core/services/employee.service';
import { EmployeeModel, EmployeeModelBuilder } from 'src/app/shared/models/employee-model';
import { AppDateAdapter } from '../helper/format-datepicker';
import { AddTimelineActivity } from 'src/app/shared/popups/popups';

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-activity-sample-screenshot',
  templateUrl: './activity-sample-screenshot.component.html',
  styleUrls: ['./activity-sample-screenshot.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class ActivitySampleScreenshotComponent implements OnInit {

  imageURL = 'api/v1/timesheet/activity-sample-image/';
  ActivitySampleDetails = [];
  totalDuration = 0;
  totalDurationLabel = '';
  date = new FormControl(new Date());
  dateValue = this.date.value;
  employees: EmployeeModel[] = [];
  selectedEmp = new FormControl(new EmployeeModelBuilder().employeeModel.id);
  screenshot = {
    img: './assets/images/shutterstock.jpg'
  };
  prevBtnImg = {
    img: './assets/images/icons8-prev-50.png'
  }
  nextBtnImg = {
    img: './assets/images/icons8-right-button-50.png'
  }
  ssNotAvail = {
    img: './assets/images/screenshot_not_available.png'
  }
  showNoContentLabel = false;

  constructor(
    public location: Location,
    private dashboardStatisticService: DashboardStatisticService,
    private sanitizer: DomSanitizer,
    private httpRequest: HttpRequestService,
    private dialog: MatDialog,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.fetchActivityDashboard();
    this.fetchEmployees();
  }

  fetchEmployees() {
    if (sessionStorage.getItem('employees')) {
      this.employees = JSON.parse(sessionStorage.getItem('employees'));
    } else {
      this.employeeService.fetchLiteEmployees().subscribe(res => {
        this.employees = res;
      });
    }
  }

  fetchActivityDashboard() {
    var date = dateTimeFormatters.formatDate(this.date.value);
    this.totalDuration = 0;
    //this.totalDurationLabel = '';

    if (this.selectedEmp.value != -1) {
      this.dashboardStatisticService.fetchActivityDashboardForEmp(date, this.selectedEmp.value).subscribe((res: any) => {
        //console.log(res);
        this.populateTable(res);
      });
    } else {
      this.dashboardStatisticService.fetchActivityDashboard(date).subscribe((res:any) => {
        // console.log(res);
        this.populateTable(res);

      });
    }
  }

  showImage(e) {
    this.dialog.open(ModalComponent, {
      data: e.target.src
    });
  }

  timeConvert(val) {
    let h = Math.floor(val / 3600);
    val %= 3600;
    let m = Math.floor(val / 60);
    let s = (val % 60);

    var min = String(m).padStart(2, "0");
    var hr = String(h).padStart(2, "0");
    var sec = String(s).padStart(2, "0");
    return hr + ":" + min + ":" + sec;
  }

  populateTable(res) {
    //console.log(res);
    this.ActivitySampleDetails = [];

    this.ActivitySampleDetails = res.details;
    // console.log(this.ActivitySampleDetails);
    this.totalDuration = res.totalWorkingHours;
    for (let i of this.ActivitySampleDetails) {
      i.startHour = i.startHour != 12 ? i.startHour < 12 ? i.startHour + ' AM' : (i.startHour - 12) + ' PM' : i.startHour + ' PM';
      i.endHour = i.endHour != 12 ? i.endHour < 12 ? i.endHour + ' AM' : (i.endHour - 12) + ' PM' : i.endHour + ' PM';
	    i.time=0;
      //console.log(i.hours)
      for (let j of i.samples) {
        //  console.log(j);
        //j.active = false;
        let projects = new Set();
        let tasks = new Set();
        if (j.projectTasks != null) {
          j.projectTasks.forEach((value, key) => {
            projects.add(value['projectName']);
            tasks.add(value['taskName']);
          });
        }
        // j.projectTasks.forEach((value, key) => {
        //   projects.add(value['projectName']);
        //   tasks.add(value['taskName']);
        // });
        j.projects = projects;
        // console.log(j.projects);
        j.tasks = tasks;
        // console.log(j.tasks)
        j.projects = Array.from(j.projects).join(',');
        j.tasks = Array.from(j.tasks).join(',');
        j.imageURL = this.ssNotAvail['img'];
        //  j.imageURL = this.httpRequest.configs.tempApiPath + this.imageURL + j.sampleId+"?x-auth-token="+sessionStorage.getItem('x-auth-token');
        if (this.selectedEmp.value != -1) {
          // j.imageURL = j.imageURL + "&emp_id=" + this.selectedEmp.value;
          if (j.active) {
            this.dashboardStatisticService.fetchActivitySampleImageForEmp(j.sampleId, this.selectedEmp.value).subscribe((res) => {
              if (res) {
                j.imageURL = res;
              }
            });
          } 
        }
        else {
          if (j.active) {
            this.dashboardStatisticService.fetchActivitySampleImage(j.sampleId).subscribe((res) => {
              if (res) {
                j.imageURL = res;
              } 
            });
          } 
          // this.dashboardStatisticService.fetchActivitySampleImage(j.sampleId).subscribe((res) => {
          //   // if (res) {
          //   //   j.imageURL = res;
          //   // } 
          //   if (j.active) {
          //     if (res) {
          //       j.imageURL = res;
          //     } else {
          //       j.imageURL = this.ssNotAvail['img'];
          //     }

          //   }
          //   else {
          //     j.imageURL = this.ssNotAvail['img'];
          //   }
          // })
        }
        // j.imageURL = this.httpRequest.configs.tempApiPath + this.imageURL + j.sampleId+"?x-auth-token="+sessionStorage.getItem('x-auth-token');

        j.startTime = new Date(j.sampleStartTime).getTime();
        j.endTime = new Date(j.sampleEndTime).getTime();
        i.time += j.endTime - j.startTime;
        j.duration = parseFloat(((((100 * (j.endTime - j.startTime) / 15) / 1000)) / 60).toFixed(2));
        // j.duration += this.timeConvert((j.endTime - j.startTime) / 1000);

        //j.duration = this.timeConvert((i.activityEndTime - i.activitityStartTime) / 1000);
      }
      //this.totalDuration += i.activityEndTime - i.activitityStartTime;
      // i.duration = this.timeConvert((i.activityEndTime - i.activitityStartTime) / 1000);
    }
    if (this.ActivitySampleDetails.length) {
      this.totalDurationLabel = 'Total time : ' + this.timeConvert(this.totalDuration / 1000);
      this.showNoContentLabel = false;
    }
    else {
      this.totalDurationLabel = 'Total time : ' + '00:00:00';
      this.showNoContentLabel = true;
    }
  }

  previousDay() {
    var currDate = this.date.value;
    this.date = new FormControl(new Date(currDate.setDate(currDate.getDate() - 1)));
    this.fetchActivityDashboard();
  }

  nextDay() {
    var currDate = this.date.value;
    this.date = new FormControl(new Date(currDate.setDate(currDate.getDate() + 1)));
    this.fetchActivityDashboard();
  }

  /**
   * This method is used to open modal which show form for manually add activity
   */
  openDialoge(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.height = '750px';
    this.dialog.open(AddTimelineActivity,dialogConfig);
  }

}