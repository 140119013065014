import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { DashboardStatisticModel } from '../../shared/models/dashboard-statistic-model';
import { ProjectHourlyStatsModel } from '../../shared/models/project-hourly-stats-model';
import { TimesheetTaskDetailsModel } from '../../shared/models/timesheet-task-details-model';
import { ActivitySampleDetailsModel} from '../../shared/models/activity-sample-details-model';
import { DailyProjectStatisticsModel} from '../../shared/models/daily-project-statistics-model';
import { ProjectWorkHoursModel} from '../../shared/models/project-work-hours-model';

@Injectable({
  providedIn: 'root'
})
export class DashboardStatisticService {
  private stateUrl = 'api/v1/timesheet';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchDashboardStatistic(): Observable<DashboardStatisticModel> {
    return this.httpRequest.get(this.stateUrl + '/get-dashboard-statistics/');
  }     

  fetchWeeklyProjectStats(start_date,end_date): Observable<ProjectHourlyStatsModel[]> {
    return this.httpRequest.get(this.stateUrl + '/get-project-task-stats/' + start_date + '/' + end_date);
  }
  fetchActivityDashboard(date):Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/activity-screenshot-dashboard/' + date);
  }

  fetchActivitySampleImage(id):Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/activity-sample-image/' + id);
  }

  fetchActivitySampleImageForEmp(id, empId):Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/activity-sample-image/' + id + "?emp_id=" + empId);
  }

  fetchProjectTimesheetStats(start_date,end_date): Observable<TimesheetTaskDetailsModel[]>{
    return this.httpRequest.get(this.stateUrl + '/project-timesheet-stats/' + start_date + '/' + end_date);
  }
  
  fetchDailyProjectStatistics():Observable<DailyProjectStatisticsModel[]>{
    return this.httpRequest.get(this.stateUrl + '/latest-project-statistics/');
  }
  fetchWeeklyTimesheet(date):Observable<ProjectWorkHoursModel[]>{
    return this.httpRequest.get(this.stateUrl + '/get-project-task-weekly-timesheet/' + date);
  }

  fetchWeeklyTimesheetForEmp(date, empId) : Observable<ProjectWorkHoursModel[]> {
    return this.httpRequest.get(this.stateUrl + '/get-project-task-weekly-timesheet/' + date + "?emp_id=" + empId);
  }

  fetchMonthlyTimesheet(date):Observable<ProjectWorkHoursModel[]>{
    return this.httpRequest.get(this.stateUrl + '/get-project-task-monthly-timesheet/' + date);
  }

  fetchMonthlyTimesheetForEmp(date, empId) : Observable<ProjectWorkHoursModel[]> {
    return this.httpRequest.get(this.stateUrl + '/get-project-task-monthly-timesheet/' + date + "?emp_id=" + empId);
  }
  fetchActivityDashboardForEmp(date, empId):Observable<ActivitySampleDetailsModel[]> {
    return this.httpRequest.get(this.stateUrl + '/activity-screenshot-dashboard/' + date + "?emp_id=" + empId);
  }

  fetchProjectTimesheetStatsForEmp(start_date,end_date, empId): Observable<TimesheetTaskDetailsModel[]>{
    return this.httpRequest.get(this.stateUrl + '/project-timesheet-stats/' + start_date + '/' + end_date + "?emp_id=" + empId);
  }
}