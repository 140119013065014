import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { FinancialYearModel } from '../../shared/models/financial-year-model';

@Injectable({
  providedIn: 'root'
})
export class FinancialYearService {
  private stateUrl = 'api/v1/fy';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchFinancialYears(): Observable<FinancialYearModel[]> {
    return this.httpRequest.get(this.stateUrl + '');
  }
 
  fetchFinancialYear(): Observable<FinancialYearModel> {
    return this.httpRequest.get(this.stateUrl + '');
  }

}
