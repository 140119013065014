import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SSOGatewayComponent } from './sso-gateway/sso-gateway.component';
import { MaterialModule } from '../shared/modules/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';  // <-- #1 import module
import { LoginService } from '../core/services/login.service';
import { SSOGatewayService } from '../core/services/sso-gateway.service';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CalendarModule } from 'angular-calendar';
import { ActivitySampleScreenshotComponent } from './activity-sample-screenshot/activity-sample-screenshot.component';
import { DailyTimesheetComponent } from './daily-timesheet/daily-timesheet.component';
import { WeeklyTimesheetComponent } from './weekly-timesheet/weekly-timesheet.component';
import { ModalComponent } from './modal/modal.component';
import { TaskComponent } from './task/task.component';
import { TaskAddEditComponent } from './task-add-edit/task-add-edit.component';
import { ProjectIntegrationComponent } from './project-integration/project-integration.component';
import { AddTimelineActivity as AddTimelineActivity } from '../shared/popups/popups';
import { MonthlyTimesheetComponent } from './monthly-timesheet/monthly-timesheet.component';
import { AttendanceLogFileComponent } from './attendance-log-file/attendance-log-file.component';
import { MyCompensatoryComponent } from './my-extra-work-hours/my-extra-work-hours.component';
import { ApplyCompensatoryComponent } from './apply-ewh/apply-ewh.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { CompensatoryActionComponent } from './ewh-action/ewh-action.component';
import { CompensatoryActionRequestComponent } from '../shared/popups/ewh-popups';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalendarModule,
    NgxChartsModule,
    NgxMatTimepickerModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    LoginService,
    SSOGatewayService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: false } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } }
  ],
  declarations: [
    LoginComponent,
    SSOGatewayComponent,
    DashboardComponent,
    HomeComponent,
    ActivitySampleScreenshotComponent,
    DailyTimesheetComponent,
    WeeklyTimesheetComponent,
    ModalComponent,
    TaskComponent,
    TaskAddEditComponent,
    ProjectIntegrationComponent,
    AddTimelineActivity,
    MonthlyTimesheetComponent,
    AttendanceLogFileComponent,
    MyCompensatoryComponent,
    ApplyCompensatoryComponent,
    CompensatoryActionComponent,
    CompensatoryActionRequestComponent
  ],
  entryComponents: [
    ModalComponent,
    AddTimelineActivity,
    CompensatoryActionRequestComponent
  ]
})
export class ComponentsModule { }
