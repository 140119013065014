import { Validators, FormGroup, FormControl } from '@angular/forms';
import { customValidators } from '../factory/common.service';

export interface GithubIntegrationModel {
    id: number;
    personalAccessToken:any
    project: {
        id:number,
        name:string
    },
    email:any
}

export class GithubIntegrationFormBuilder {

    githubIntegrationModel: GithubIntegrationModel = {
        id: null,
        personalAccessToken:null,
        project: null,
        email:null
    };

    githubIntegrationForm : FormGroup = new FormGroup({
        personalAccessToken: new FormControl(this.githubIntegrationModel.personalAccessToken, [Validators.required]),
        id: new FormControl(this.githubIntegrationModel.id, [Validators.required]),
        email:  new FormControl(this.githubIntegrationModel.email,[Validators.required, customValidators.emailValidator]),
    });

    constructor() {
        this.githubIntegrationForm.valueChanges.subscribe(val => {
            this.githubIntegrationModel.personalAccessToken = val.personalAccessToken;
            this.githubIntegrationModel.id = val.id;
            this.githubIntegrationModel.email = val.email;
        })
    }
   setModelVals(res: GithubIntegrationModel) {
        this.githubIntegrationForm.controls.personalAccessToken.setValue(res.personalAccessToken);
        this.githubIntegrationForm.controls.id.setValue(res.id);
        this.githubIntegrationForm.controls.email.setValue(res.email);
    }


}