import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { ActivityTimelineModel } from 'src/app/shared/models/activity-timeline-model';

@Injectable({
  providedIn: 'root'
})
export class ManualActivityService {

  private stateUrl = 'api/v1/manual-activity';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  /**
   * This service is responsible for validate activity timeline existance based on param
   * @param activityStartDate 
   * @param activityEndDate 
   */
  checkTimelineExist(activityStartDate:any,activityEndDate:any): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/checkTimeLineExistRequest/' + activityStartDate + '/' + activityEndDate);
  }

  /**
   * This service is responsible for submitting activity timeline manually
   * @param activityTimelineModel 
   */
  addTimelineManually(activityTimelineModel:ActivityTimelineModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/add-activity', activityTimelineModel);
  }
  
}
